import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_SRC } from '../../constants/apiContants';
import swal from 'sweetalert';
import ReactTooltip from 'react-tooltip';
import Button from 'react-bootstrap-button-loader';
import Moment from 'react-moment';
import formatMoney from '../Helpers/formatMoney';
import Select from 'react-select'

const Licence = (props)=>{

    
    Moment.globalFormat = 'D MMM YYYY @ h:m A';
    const selectStyle = {input: styles => ({borderColor: "#ebebeb", height: "45px", padding: "12px 20px", fontSize: "14px"})}
    let [issaving, setIssaving] = useState(false);
    let [licences, setLicences] = useState([]);
    let [phone, setPhone] = useState(null);
    let [type, setType] = useState(1);
    let [cost, setCost] = useState(0);
    let [school, setSchool] = useState('');
    let [schoolid, setSchoolid] = useState(0);
    let [students, setStudents] = useState(0);
    let [sold, setSold] = useState(0);

    useEffect(() => {

        // Get Previously Purchased Licenses
        axios({
            url:API_SRC+'agent-licences',
            method:'post',
            data:{agent_id:props.user.id},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                
                setLicences(response.data);
            }
        });
    }, [props.user.id, sold]);


    // Calculate Cost
    const calculateCost = (phone, type) => {
        if (phone && phone.length > 6){
            axios({
                url:API_SRC+'calculate-licence-cost',
                method:'post',
                data:{phone:phone, type:type},
                headers:{'Content-Type':'application/json'}
            }).then((response)=>{
                setCost(response.data.cost);
                setStudents(response.data.students);
                setSchool(response.data.school);
                setSchoolid(response.data.school_id);
            }).catch(()=>{
                setStudents(0);
                setCost(0);
            })
        }
        else{
            setSchool('');
            setStudents(0);
            setCost(0)
        };
    }
    // Purchase Licence
    const purchaseLicence = () => {
        setIssaving(true);
        // update tournament
        axios({
            url:API_SRC+'purchase-licence',
            method:'post',
            data:{school_id:schoolid, type:type.value, agent_id:props.user.id},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            if (response.data.cost){
                setIssaving(false);
                setSold(sold+1);
                setPhone('');
                setCost(0);
                setStudents(0);
                setSchool('');
                swal('', `${type.label}-year licence for ${school} purchased successfully!`, 'success');
            }else{
                setIssaving(false);
                swal('', 'Ensure your wallet is well funded :)', 'info');
            }
        }).catch(()=>{
            setIssaving(false);
        })

    }


    return (
        <>
        <Header/>
        <div className="container">
        <div className="row">
        <div className="col-md-5">
        <h6 className="text-center">Purchase Licence</h6>
        <div className="row">
        <div className="form-group col-md-8">
            <label>Teacher's Phone</label>
            <input type="text" name="phone" value={phone} onChange={(e)=>{setPhone(e.target.value); calculateCost(e.target.value, type)}} className="form-control" placeholder="e.g.: +2347039012508" required />
        </div>
        <div className="form-group col-md-4">
            <label>Licence Type</label>
            <Select styles={selectStyle} options={[{label:"One Year", value:1}, {label:"Three Years", value:3}, {label:"Five Years", value:5}, {label:"Ten Years", value:10}]} onChange={(e)=>{setType(e.value); calculateCost(phone, e.value)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Students</label>
            <input type="text" name="cost" value={students} className="form-control" placeholder="Number of Students"/>
        </div>
        <div className="form-group col-md-8">
            <label>Cost</label>
            <input type="text" name="cost" value={`₦${formatMoney(cost)}`} className="form-control" placeholder="Autogenerated cost"/>
        </div>
        <div className="form-group col-md-12">
            <label>School</label>
            <input type="text" name="school" value={school} className="form-control" placeholder="School name"/>
        </div>
        <div className="form-group col-md-6">
            <ReactTooltip/>
            <Button disabled={cost ? false : true} data-tip="Pay with wallet" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-credit-cards text-30 text-primary pb-3"></span>} onClick={purchaseLicence}></Button>
            
        </div>
        </div>
        </div>
        <div className="col-md-7">
        <h6 className="text-center">Recently Purchased Licences</h6>
        <div className="card">
        <table className="table table-responsive">
            <thead>
                <tr>
                    <th scope="col">School</th>
                    <th scope="col">Key</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Purchased</th>
                    <th scope="col">Expires</th>
                    <th scope="col">Remarks</th>
                </tr>
            </thead>
            <tbody>
            {
                licences.map(licence =>{
                    return(<tr>
                        <td>{licence.school.name}</td>
                        <td>{licence.key}</td>
                        <td>{`₦${formatMoney(licence.cost)}`}</td>
                        <td><Moment unix>{licence.purchased_on}</Moment></td>
                        <td><Moment unix>{licence.expires_on}</Moment></td>
                        <td>{licence.comment}</td>
                    </tr>)
                })
            }
            </tbody>
            </table>        
        </div>
        </div>
        </div>
        </div>
        <Footer/>
        </>
    )
}
export default Licence;