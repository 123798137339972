import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import PaywithPaystack from './PaywithPaystack';
// import PaywithBolma from './PaywithBolma';
import Transactions from './Transactions';



const FundWallet = (props) => {
    let [issaving, setIssaving] = useState(false);
    let [amount, setAmount] = useState();
    let [doRefresh, setdoRefresh] = useState(false);
      
        return (

        
            <>
        <Header/>
        <div className="container">
        <div className="row">
        <div className="col-md-5">
        <h6 className="text-center">Fund Wallet</h6>
        <div className="row">
        <div className="form-group col-md-12">
            <label>Amount</label>
            <input type="number" name="amount" value={amount} onChange={(e)=>{setAmount(e.target.value)}} className="form-control" placeholder="e.g.: 50000" required />
        </div>
        <div className="form-group col-md-6">
        <PaywithPaystack agent_id={props.user.id} email={props.user.email} amount={amount} issaving={issaving} setIssaving={setIssaving} setdoRefresh={setdoRefresh} doRefresh={doRefresh} setAmount={setAmount}/>
            
        </div>
        </div>
        </div>
        <div className="col-md-7">
        <Transactions agent_id={props.user.id} doRefresh={doRefresh} />
        </div>
        </div>
        </div>
        <Footer/>
        </>
        );
    }
export default FundWallet;