import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {API_SRC, CLOUDINARY_IMAGE} from '../../constants/apiContants';

class SchoolDetail extends Component {
    constructor(props) {
        super(props);
        const id = props.match.params.id
        this.state = {
            data: 0,
            school: {},
            schoolid: id,
            progress: 100,
            title: ""
         }
        this.setNewNumber = this.getSchool.bind(this)

    }

    componentDidMount (){
        this.getSchool()
    }
    getSchool() {
    
        var self = this;
        self.setState({progress: 100});
        const url = API_SRC + 'schools';
        const payload = { "school_id": this.state.schoolid }
        
        axios({
          method:'post',
          url: url,
          data: payload,
          headers: {'Content-Type':'application/json'}
        }).then((school)=>{
            self.setState({school:school.data, title: 'Schoola | '+school.data.name, progress: 100})
        }).catch(()=>{});
     }

    render() {
        return (
            <>
            <Header title={this.state.title} progress={this.state.progress} />
                <div className="page-content-wrapper" style={{alignItems:'center'}}>
                      
                          <div className="col-md-12">
                              <div className="card user-info-card">
                              <div className="card-body p-4 d-flex align-items-center">
                                <div className="user-profile mr-3">
                                  <img src={`${CLOUDINARY_IMAGE}${this.state.school.logo}`}  alt="" width="100"/>
                                </div>
                                <div className="user-info">
                                  <p className="mb-0 text-black">{this.state.school.address}</p>
                                    <h5>{this.state.school.name} </h5>
                                </div>
                              </div>
                            </div>
                                          
                          <div className="card user-data-card">
                            <div className="card-body">
                              <div className="single-profile-data d-flex align-items-center justify-content-between">
                                <div className="title d-flex align-items-center"><i className="lni-user"></i><span>Name</span></div>
                                <div className="data-content">{this.state.school.name}</div>
                              </div>
                              <div className="single-profile-data d-flex align-items-center justify-content-between">
                                <div className="title d-flex align-items-center"><i className="lni-phone-handset"></i><span>Phone</span></div>
                                <div className="data-content">{this.state.school.phone}</div>
                              </div>
                              <div className="single-profile-data d-flex align-items-center justify-content-between">
                                <div className="title d-flex align-items-center"><i className="lni-envelope"></i><span>Email Address</span></div>
                                <div className="data-content">{this.state.school.email}                                </div>
                              </div>
                              <div className="single-profile-data d-flex align-items-center justify-content-between">
                                <div className="title d-flex align-items-center"><i className="lni-map-marker"></i><span>Address</span></div>
                                <div className="data-content">{this.state.school.address}</div>
                              </div>
                            </div>
                          </div>  
                          </div>
                          <div className="col-md-6">
                          </div>

                     
                  </div>
            
                <Footer />
                </>
        );
    }
}

SchoolDetail.propTypes = {

};

export default withRouter(SchoolDetail);