import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB, CLOUDINARY_IMAGE, SCHOOLA_TOURNAMENT_LINK } from '../../constants/apiContants';
import ReactHtmlParser from 'react-html-parser'; 
import Moment from 'react-moment';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const Tournaments = (props) => {
    let [tournies, setTournies ] = useState([])
    Moment.globalFormat = 'D MMM YYYY @ h:m A';

    useEffect(()=>{
        axios({
            url:API_QB+'agent-tournaments',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{agent_id:props.user.id}
        }).then((response)=>{
            console.log(typeof(response.data))
            if (typeof(response.data) === 'object'){
                setTournies(response.data)
            }
        });
    }, [props.user.id]);
    return (
        <>
        <Header title="Schooola - Tournaments" progress={100} />
        <div className="container">
        <div className="row">
        <div className="col-md-1">
        <Link data-tip="Back to Tournament Home" className="lni-arrow-left-circle text-30 m-2" to="/tournament"/>
        <Link data-tip="Create New Tournament" className="lni-write text-30 m-2" to="/add-tournament"/>
        <ReactTooltip/>
        </div>
        <div className="col-md-11">
            <div className="card ">
            <table className="table table-responsive">
            <thead>
                <tr>
                    <th scope="col">Tournament</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                    <th scope="col">Fee</th>
                    <th scope="col">Start</th>
                    <th scope="col">End</th>
                    <th scope="col">Link</th>
                    <th scope="col">Preview</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
            {tournies.map(tournament => {
                return (   
        
                    <tr key={tournament.id+1}>
                        <td key={tournament.id+2}>
                        <img src={CLOUDINARY_IMAGE+tournament.poster} alt={tournament.name}/>
                        <span>{ tournament.name }</span>
                        </td>
                        <td key={tournament.id+3}>{ tournament._type.toUpperCase() }</td>
                        <td key={tournament.id+4}> { ReactHtmlParser (tournament.description.slice(0,200)) }</td>
                        <td key={tournament.id+5}><NumberFormat value={ tournament.fee } displayType={'text'} thousandSeparator={true} prefix={'₦'} /></td>
                        <td key={tournament.id+6}><Moment unix>{tournament.starts_at-3600}</Moment></td>
                        <td key={tournament.id+7}><Moment unix>{tournament.ends_at-3600}</Moment></td>
                        <td><Link className="text-danger lni-link text-20" to={{pathname:`${SCHOOLA_TOURNAMENT_LINK}${tournament.id}`}} target="_blank"/></td>
                        <td><Link className="text-danger lni-image text-20" to={`/tournament-details/${tournament.id}`}/></td>
                        <td><Link className="text-danger lni-trash text-20" to="#"/></td>
                    </tr>
                    )
            })}
            </tbody>
            </table>        
            </div>
        </div>
        </div>
        </div>
        <Footer />
        </>
    );
}


export default Tournaments;