export const API_SRC = process.env.REACT_APP_API_SRC;
export const API_QB = process.env.REACT_APP_API_QB;
export const ACCESS_TOKEN_NAME = process.env.REACT_APP_ACCESS_TOKEN_NAME;
export const BOLMAPAY_API = process.env.REACT_APP_BOLMAPAY_API
export const CLOUDINARY_IMAGE = process.env.REACT_APP_CLOUDINARY_IMAGE
export const SCHOOLA_LOGO = process.env.REACT_APP_SCHOOLA_LOGO
export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY
export const SUCCESS_SOUND = process.env.REACT_APP_SUCCESS_SOUND
export const CLOUDINARY_UPLOAD = process.env.REACT_APP_CLOUDINARY_UPLOAD
export const SCHOOLA_TOURNAMENT_LINK = process.env.REACT_APP_SCHOOLA_TOURNAMENT_LINK
export const NAMELOGO = process.env.REACT_APP_NAMELOGO
export const LOGO = process.env.REACT_APP_LOGO
export const PAYSTACK_KEY = process.env.REACT_APP_PAYSTACK_KEY
export const CIRCLE_TRANSFORM = "w_400,h_400,c_crop,g_face,r_max/w_200/"
export const SPACE_ENDPOINT = process.env.REACT_APP_SPACE_ENDPOINT
export const SPACE_BUCKET_NAME = process.env.REACT_APP_SPACE_BUCKET_NAME
export const SPACE_ACCESS_ID = process.env.REACT_APP_SPACE_ACCESS_ID
export const SPACE_SECRET_KEY = process.env.REACT_APP_SPACE_SECRET_KEY
