import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB, CLOUDINARY_IMAGE } from '../../constants/apiContants';
import Tiny from '../Editor/Tiny'
import swal from 'sweetalert';
import { Link, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import doUpload from '../Helpers/doUpload';
import Button from 'react-bootstrap-button-loader';
import moment from 'moment';
import Moment from 'react-moment';
import Select from 'react-select'

const Singletournament = (props)=>{

    Moment.globalFormat = 'yyyy-MM-ddThh:mm';

    let params = useParams();
    let [participants, setParticipants] = useState([]);
    let [issaving, setIssaving] = useState(false);
    let [banks, setBanks] = useState([]);
    let [poster, setPoster] = useState('tournament.jpg');
    let [bank, setBank] = useState('private');
    let [name, setName] = useState('');
    let [description, setDescription] = useState('');
    let [type, setType] = useState('rapid');
    let [fee, setFee] = useState(1000);
    let [ispublic, setIspublic] = useState(false);
    let [starts, setStarts] = useState('');
    let [ends, setEnds] = useState('');
    let [selectSubjects, setSelectSubjects] = useState([]);
    let [subjects, setSubjects] = useState('');
    let [levels, setlevels] = useState('');
    let [selectLevels, setSelectLevels] = useState([]);
    let timer = 30000;
    const selectStyle = {input: styles => ({borderColor: "#ebebeb", height: "45px", padding: "12px 20px", fontSize: "14px"})}

    useEffect(() => {
        // Get Participants
        const interval = setInterval(() => {
            if (new Date(ends) > new Date()){
                axios({
                    url:API_QB+'agent-tournament',
                    method:'post',
                    data:{agent_id:props.user.id, tournament_id:params.id},
                    headers:{'Content-Type':'application/json'}
                }).then((response)=>{
                    setParticipants(response.data.leaderboard);
                });
            }
          }, timer );
          return () => clearInterval(interval);
    });
    
    useEffect(() => {

        // Get Banks
        axios({
            url:API_QB+'agent-banks',
            method:'post',
            data:{agent_id:props.user.id, has_questions:true},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            let _banks = []
            response.data.map(bank =>{
                _banks.push(bank.name)
                return bank;
            })
            setBanks(_banks);
        });

        // Get Subjects
        axios({
            url:API_QB+'get-subjects',
            method:'post',
            headers: {'Content-Type':'application/json'},
            data: {agent_id:props.user.id}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                let _subjects = []
                response.data.map(question => {
                    return _subjects.push({value:question.subject, label:question.subject})
                });
                setSelectSubjects(_subjects)
            }
        });

        // Get Levels
        // get levels
        axios({
            url:API_QB+'get-levels',
            method:'post',
            headers: {'Content-Type':'application/json'},
            data: {agent_id:props.user.id}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                let _levels = []
                response.data.map(question => {
                    return _levels.push({value:question.level, label:question.level})
                });
                setSelectLevels(_levels)
            }
        });

        // Get Tournament
        axios({
            url:API_QB+'agent-tournament',
            method:'post',
            data:{agent_id:props.user.id, tournament_id:params.id},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            let x = []
            if (response.data.tournament.levels){
                response.data.tournament.levels.split(',').map(item => {
                    return x.push({label:item, value:item})
                })
            }
            let y = []
            if (response.data.tournament.subjects){
                response.data.tournament.subjects.split(',').map(item => {
                    return y.push({label:item, value:item})
                })
            }
            setParticipants(response.data.leaderboard);
            setName(response.data.tournament.name);
            setPoster(response.data.tournament.poster);
            setBank(response.data.tournament.bank);
            setType(response.data.tournament._type);
            setDescription(response.data.tournament.description);
            setFee(response.data.tournament.fee);
            setIspublic(response.data.tournament.is_public);
            setStarts(moment.unix(response.data.tournament.starts_at-3600).format("YYYY-MM-DDTHH:mm"));
            setEnds(moment.unix(response.data.tournament.ends_at-3600).format("YYYY-MM-DDTHH:mm"));
            setSubjects(y);
            setlevels(x);
        });
    }, [props.user.id, params]);

    const validateTiming = (starts, ends) =>{
        let start = moment(starts);
        let end = moment(ends);
        let duration = moment.duration(end.diff(start));
        let difference = duration.asMinutes();
        if (difference<59){
            swal('', 'There should be atleast an hour difference between when a tournament starts and when it ends');
            setEnds(end.add(60, 'minutes').format('YYYY-MM-DDTHH:MM'))
        }
    }

    const filterSelected = (arrayobject)=>{
        let list = [];
        arrayobject.map(selected=>{
            return list.push(selected.value);
        });
        return list.join();
    }

    const doUpdate = () => {
        setIssaving(true);
        // update tournament
        axios({
            url:API_QB+'update-tournament',
            method:'post',
            data:{id:params.id, bank:bank, poster:poster, name:name, description:description, type:type, subjects:filterSelected(subjects), levels:filterSelected(levels), fee:fee, is_public:ispublic, starts:starts, ends:ends, agent_id:props.user.id},
            headers:{'Content-Type':'application/json'}
        }).then(()=>{
            setIssaving(false);
        }).catch(()=>{
            setIssaving(false);
        })

    }


    return (
        <>
        <Header/>
        <div className="container">
        <h5>{name}</h5>
        <div className="row">
        <div className="col-md-6">
        <img src={`${CLOUDINARY_IMAGE}${poster}`} style={{width:"600px", borderRadius: "25px"}} alt="" />
        <div className="row">
        <div className="form-group col-md-4">
            <label>Poster</label>
            <input name="poster" type="file" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setPoster(data.Key);
                    setIssaving(false);
                })
            })} className="form-control" required />
        </div>
        <div className="form-group col-md-8">
            <label>Title</label>
            <input type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value)}} className="form-control" placeholder="e.g.: Awesome Spelling Bee" required />
        </div>
        <div className="form-group col-md-12">
            <label>Description</label>
            <Tiny name="description" value={description} height={200} handleEditorChange={(content, editor) => {setDescription(editor.getContent())}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Entry Fee</label>
            <input disabled type="number" name="fee" value={fee} className="form-control" placeholder="e.g.: 1500" required />
        </div>
        <div className="form-group col-md-4">
            <label>Subjects</label>
            <Select styles={selectStyle} isMulti options={selectSubjects} onChange={(e)=>{setSubjects(e)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Levels</label>
            <Select styles={selectStyle} isMulti options={selectLevels} onChange={(e)=>{setlevels(e)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Tournament Type</label>
            <select name="type" value={type} onChange={(e)=>{setType(e.target.value)}} className="form-control">
                <option value="rapid">Rapid</option>
                <option value="league">League</option>
                <option value="quiz">Quiz</option>
                <option value="practice">Practice</option>
            </select>
        </div>
        <div className="form-group col-md-4">
            <label>Question Bank</label>
            <select name="bank" value={bank} onChange={(e)=>{setBank(e.target.value)}} className="form-control">
                {
                    banks.map(bank => {
                    return(
                        <option key={bank} value={bank}>{bank}</option>)
                    })
                }
            </select>
        </div>
        <div className="form-group col-md-4">
            <label>Accesibility</label>
            <select disabled name="ispublic" value={ispublic? 'public':'private'} className="form-control">
                <option value="private">Private</option>
                <option value="public">Public</option>
            </select>
        </div>
        <div className="form-group col-md-6">
            <label>Starting Date & Time</label>
            <input type="datetime-local" name="starts" value={starts} onChange={(e)=>{setStarts(e.target.value); validateTiming(e.target.value, ends)}} className="form-control" required />
        </div>
        <div className="form-group col-md-6">
            <label>Ending Date & Time</label>
            <input type="datetime-local" name="ends" value={ends} onChange={(e)=>{setEnds(e.target.value); validateTiming(starts, e.target.value)}} className="form-control" required />
        </div>
        <div className="form-group col-md-6">
            <ReactTooltip/>
            <Button data-tip="Update Tournament" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary pb-3"></span>} onClick={doUpdate}></Button>
            <Link to="/tournaments" data-tip="My Tournaments" className="lni-list text-30 ml-3 text-primary" />
        </div>
        </div>
        </div>
        <div className="col-md-6">
        <h6 className="text-center"> Participants ({participants.length}) </h6>
        <table className="table table table-striped">
            <thead>
                <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Student</th>
                    <th scope="col">Questions</th>
                    <th scope="col">Points</th>
                    <th scope="col">School</th>
                </tr>
            </thead>
            <tbody>
            {
                participants.map(student =>{
                    return(<tr>
                        <td>{student.rank}</td>
                        <td>{student.name}</td>
                        <td>{student.questions}</td>
                        <td>{student.points}</td>
                        <td>{student.school}</td>
                    </tr>)
                })
            }
            </tbody>
            </table>        
        </div>
        </div>
        </div>
          
        <Footer/>
        </>
    )
}
export default Singletournament;