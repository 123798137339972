import axios from 'axios';
import { usePaystackPayment } from 'react-paystack';
import { API_SRC, PAYSTACK_KEY } from '../../constants/apiContants';
import Button from 'react-bootstrap-button-loader';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';

const PaywithPaystack = (props) => {

    const UpdateUserWallet = (reference) => {
        const payload = {
            "agent_id": props.agent_id,
            "amount": props.amount,
            "narration": "Paid with paystack: "+reference.reference,
            "is_inflow": true,
        }
        axios({
            method:'post',
            url:API_SRC + 'agent-transaction',
            headers: {'Content-Type':'application/json'},
            data:payload
        }).then(()=>{
            props.setIssaving(false)
            props.setAmount('')
            props.setdoRefresh(!props.doRefresh)
        }).catch(()=>{
          props.setIssaving(false)
          swal('', 'Wallet could not be funded!', 'info');
        })
    }
    
    // you can call this function anything
    const onSuccess = (reference, amount) => {
      // Implementation for whatever you want to do with reference and after success call.
      UpdateUserWallet(reference, amount)
      console.log(reference);
    };
    
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
      props.setIssaving(false);
    }
    
    const config = {
        reference: (new Date()).getTime(),
        email: props.email,
        amount: props.amount*100,
        publicKey: PAYSTACK_KEY,
    };
    const initializePayment = usePaystackPayment(config);
    return (
      <>
      <Button disabled={props.amount ? false : true} data-tip="Pay with Paystack" loading={props.issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-credit-cards text-30 text-primary pb-3"></span>} onClick={() => {initializePayment(onSuccess, onClose); props.setIssaving(true)}}></Button>
      <ReactTooltip/>
      </>
    );
};

export default PaywithPaystack