import React from 'react';
import { Link } from "react-router-dom";



const AgentFooter = () => {
  return (
      <div className="mt-100">
      <div className="footer-nav-area" id="footerNav">
      <div className="suha-footer-nav h-100">
      <ul className="h-100 d-flex align-items-center justify-content-between">
        <li className="active"><Link to="/home"><i className="lni-home"></i>Home</Link></li>          
        <li><Link to="/fund-wallet"><i className="lni-wallet"></i>Wallet</Link></li>
        <li><Link to="/tournament"><i className="lni-cup"></i>Tournament</Link></li>
        <li><Link to="/schools"><i className="lni-school-bench-alt"></i>Schools</Link></li>
      </ul>
      </div>
      </div>
      </div>
        );
};

export default AgentFooter;