import React, { useState } from 'react';
import AgentHome from './AgentHome'
import SubagentHome from './SubagentHome'
const Home = (props) => {

      let dashboard = useState(props.user.agent_id ? <SubagentHome user={props.user}/> : <AgentHome user={props.user}/>);
        return (
            <>
            {dashboard}
            </>
        );
}

export default Home;