import { useHistory, withRouter } from "react-router-dom";
import React, { useState } from 'react';
import '../res/style.css';
import '../../App.css';
import LoadinBar from 'react-top-loading-bar';
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import doLogout from '../Helpers/doLogout';
import { NAMELOGO } from '../../constants/apiContants';
// import useProfile from '../Hooks/User';


const Header = (props) => {
   
  let [progress, setProgress] = useState(props.progress);
  let history = useHistory()

    return (

      <span className="mb-100">
      <Helmet>
      <title>{props.title}</title>
      <meta charset="utf-8" />
      </Helmet>
      <LoadinBar color='#f11946' progress={progress} onLoaderFinished={() => setProgress(0)} />
      <div className="header-area" id="headerArea">
      <div className="container h-100 d-flex align-items-center justify-content-between">

      <div className="logo-wrapper"><br/><p><Link to="/home"><img src={NAMELOGO} width="100" alt=""/></Link></p></div>
      <div onClick={()=>{doLogout(); history.push('/agent-login')}} className="btn d-flex justify-content-between flex-wrap noHover" ><i className="lni-power-switch text-20"></i><span></span><span></span><span></span></div>

      </div>
      </div>


      </span>

    )
}


export default withRouter(Header);