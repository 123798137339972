import React, { useState } from 'react';
import AgentFooter from './AgentFooter';
import useProfile from '../Hooks/User';
import SubagentFooter from './SubagentFooter';


const Footer = () => {
        let user = useProfile();
        let foot = useState(user.agent_id ? <SubagentFooter/>:<AgentFooter/>)


  return (
      <>
      {foot}
      </>
        );
};

export default Footer;