import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import { Link } from "react-router-dom";
import axios from 'axios';
import {API_SRC, CLOUDINARY_IMAGE, CIRCLE_TRANSFORM} from '../../constants/apiContants';
// import Pagination from "react-js-pagination";

const Schools = (props) => {
      let [schools, setSchools] = useState([])


      useEffect(()=>{
        axios({
          url:API_SRC+'schools',
          method:'post',
          headers:{'Content-Type':'application/json'},
          data:{agent_id:props.user.id}
        }).then((response)=>{
          if (typeof(response.data) === 'object'){
            setSchools(Array.from(response.data))
          }
        }).catch((error)=>{setSchools([])})
      
      }, [props.user.id])

      return (
        <>
        <Header title="Schooola - Schools" progress={0} />
        <div className="container">
        <div className="row">
        <div className="col-md-12">
            <div className="card ">
            <table className="table table-responsive">
            <thead>
                <tr>
                    <th scope="col">Logo</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Preview</th>
                </tr>
            </thead>
            <tbody>
            {schools.map(school => {
                return (   
        
                    <tr key={school.id+1}>
                        <td key={school.id+2}>
                        <img src={CLOUDINARY_IMAGE+CIRCLE_TRANSFORM+school.logo} alt={school.name}/>
                        </td>
                        <td key={school.id+3}>{ school.name }</td>
                        <td key={school.id+3}>{ school.email }</td>
                        <td key={school.id+3}>{ school.phone }</td>
                        <td><Link className="text-danger lni-image text-20" to={`/school-detail/${school.id}`}/></td>
                    </tr>
                    )
            })}
            </tbody>
            </table>        
            </div>
        </div>
        </div>
        </div>
        <Footer />
        </>
      )
}

export default Schools