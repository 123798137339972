import React from 'react';
import { CLOUDINARY_IMAGE, CIRCLE_TRANSFORM } from '../../constants/apiContants';
import { Link } from "react-router-dom";
import Header from '../Header/Header';
import Footer from '../Footer/Footer'


const Home = (props) => {


        return (
          <>
                <Header title="Schoola - Home" progress={100} />
                <div className="content">
                <div className="card">
                  <div className="card-body p-4 d-flex align-items-center">
                  <div className="user-profile mr-3"><img width="100px" src={`${CLOUDINARY_IMAGE+CIRCLE_TRANSFORM}${localStorage.getItem('passport')}`} alt=""/></div>
                  <div className="user-info">
                    <p className="mb-0 text-black">{props.user.phone}</p>
                                <h5 className="mb-0">{props.user.name}</h5>
                                <p>{props.user.email}</p>
              </div>
            </div>
          </div>    
              


      <div className="product-catagories-wrapper pt-6">
          <div className="section-heading">
          </div>
          <div className="product-catagory-wrap">
            <div className="row">
              
              <div className="col-6">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="profile"><i className="lni-user"></i><span>Profile</span></Link></div>
                </div>
              </div>
              <div className="col-6">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="questions"><i className="lni-question-circle"></i><span>Questions</span></Link></div>
                </div>
              </div>
              <div className="col-6">
                <div className="card mb-2 mt-3 catagory-card">
                  <div className="card-body"><Link to="add-question"><i className="lni-add-file"></i><span>New Question</span></Link></div>
                </div>
              </div>
              <div className="col-6">
                <div className="card mb-2 mt-3 catagory-card">
                  <div className="card-body"><Link to="bulk-questions"><i className="lni-files"></i><span>Bulk Questions</span></Link></div>
                </div>
              </div>
            
            
            </div>
        </div>
                    </div>

                </div>

                <Footer />
           </>
        );
}

export default Home;