import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
// import PaywithPaystack from './PaywithPaystack';
// import PaywithBolma from './PaywithBolma';
import Button from 'react-bootstrap-button-loader';
import doUpload from '../Helpers/doUpload';
import ReactTooltip from 'react-tooltip';
import Agents from './Agents';
import Select from 'react-select'
import swal from 'sweetalert';
import axios from 'axios';
import { API_SRC } from '../../constants/apiContants';



const AddAgent = (props) => {
    const selectStyle = {input: styles => ({borderColor: "#ebebeb", height: "45px", padding: "12px 20px", fontSize: "14px"})}
    let [issaving, setIssaving] = useState(false);
    let [name, setName] = useState();
    let [phone, setPhone] = useState();
    let [email, setEmail] = useState();
    let [passport, setPassport] = useState();
    let [gender, setGender] = useState();
    let [doRefresh, setdoRefresh] = useState(false);

    const addSubagent = ()=>{
        setIssaving(true);
        axios({
            url: API_SRC+'new-subagent',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data: {agent_id:props.user.id, name:name, phone:phone, email:email, gender:gender, passport:passport}
        }).then((response) => {
            if(response.data.id){
                swal('', 'Agent registered successfully', 'success');
                setdoRefresh(!doRefresh);
            }else{
                swal('', 'Agent could not be registered', 'info');
            }
            setIssaving(false);
        })
    }
      
        return (

        
            <>
        <Header/>
        <div className="container">
        <div className="row">
        <div className="col-md-5">
        <h6 className="text-center">Add Agent</h6>
        <div className="row">
        <div className="form-group col-md-9">
            <label>Name</label>
            <input type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value)}} className="form-control" required />
        </div>
        <div className="form-group col-md-3">
            <label>Gender</label>
            <Select styles={selectStyle} options={[{label:"Male", value:'Male'}, {label:"Female", value:'Female'}]} onChange={(e)=>{setGender(e.value)}}/>
        </div>
        <div className="form-group col-md-6">
            <label>Phone</label>
            <input type="tel" name="phone" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="form-control" required />
        </div>
        <div className="form-group col-md-6">
            <label>Email</label>
            <input type="email" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className="form-control" required />
        </div>
        <div className="form-group col-md-12">
            <label>Passport</label>
            <input type="file" name="passport" className="form-control" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setPassport(data.Key);
                    setIssaving(false);
                })
            })}/>
        </div>
        <div className="form-group col-md-12">
        <Button disabled={(issaving ? true : false) || (name ? false : true) || (phone ? false : true) || (email ? false : true)} data-tip="Save Agent" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary pb-3"></span>} onClick={() => {addSubagent(); setIssaving(true)}}></Button>
        <ReactTooltip/>
            
        </div>
        </div>
        </div>
        <div className="col-md-7">
        <Agents agent_id={props.user.id} doRefresh={doRefresh} setdoRefresh={setdoRefresh} />
        </div>
        </div>
        </div>
        <Footer/>
        </>
        );
    }
export default AddAgent;