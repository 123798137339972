import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB, SUCCESS_SOUND } from '../../constants/apiContants';
import Tiny from '../Editor/Tiny'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const AddQuestion = (props) => {
    let audio = new Audio(SUCCESS_SOUND);
    let [banks, setBanks] =  useState([]);
    let [progress, setProgress] =  useState(100);
    let agent_id = props.user.agent_id ? props.user.agent_id : props.user.id
    let [bank, setBank] = useState()
    let [subject, setSubject] = useState()
    let [level, setLevel] = useState()
    let [topic, setTopic] = useState()
    let [editor, setEditor] = useState({question:'', answer:'', option_one:'', option_two:'', option_three:'', explanation:''})


    useEffect(() => {
        setProgress(20);
        
        axios({
            method:'post',
            url: API_QB + `agent-banks`,
            data: { "agent_id": agent_id },
            headers: {'Content-Type':'application/json'}
        }).then((response)=>{
            setProgress(50)
            if (response.data) {
                setProgress(100)
                setBanks(Array.from(response.data))
            }
        }).catch(()=>{})
    }, [agent_id])



    const changeEditor = (content, editor) => {
        // this.setState({[editor.id]:editor.getContent()})
        setEditor(prevState => ({ ...prevState, [editor.id]:editor.getContent() }));
      }

    const saveQuestion = () => {
        if (bank && subject && level && topic && editor.question && editor.answer && editor.option_one && editor.explanation){
        setProgress(20)
        const payload = {
            'bank':bank,
            'subject': subject,
            'level':level,
            'topic': topic,
            'question':editor.question,
            'answer':editor.answer,
            'options':[editor.option_one, editor.option_two, editor.option_three],
            'explanation':editor.explanation,
            'agent_id':agent_id
        }

        axios({
            method:'post',
            url:API_QB + `add-question`,
            data:payload,
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            setProgress(50)
            if (response.data) {
                setProgress(100)
                setEditor({question:'', answer:'', option_one:'', option_two:'', option_three:'', explanation:''})
                audio.play()
                }
        }).catch(()=>{});
    }
        else{
            swal('Oops!', 'You must add at least an answer and an explation', 'info');
        }

    }

    return (
        <>
    <Header title="Schoola - Add Questions" progress={progress} />
    <div className="container">
        <h5>Add New Question</h5>
        <form>
                    
                            
                    <div className="row">
                    <div className="col-md-3">
                    <div className="form-group text-left">
                            <label htmlFor="bank">Select Bank</label>
                            <select type="text" name="bank" className="form-control" id="bank" placeholder="Bank" value={bank} onChange={(e) =>{setBank(e.target.value)}}>
                                <option>Select Bank</option>
                                {
                                    banks.map(bank => {
                                        return (  <option key={bank.id} value={bank.name}>{bank.name}</option>)
                                    })
                                }
                                </select>
                            </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group text-left">
                            <label htmlFor="subject">Subject</label>
                            <input type="text" name="subject" className="form-control"  id="subject" placeholder="Subject e.g.: Agricalture" value={subject} onChange={(e) =>{setSubject(e.target.value)}}/>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group text-left">
                            <label htmlFor="level">Level</label>
                            <select type="text" name="level" className="form-control" id="level" value={level} onChange={(e) =>{setLevel(e.target.value)}}>
                                <option>Select Level</option>
                                <option value="Primary One">Primary One</option>
                                <option value="Primary Two">Primary Two</option>
                                <option value="Primary Three">Primary Three</option>
                                <option value="Primary Four">Primary Four</option>
                                <option value="Primary Five">Primary Five</option>
                                <option value="Primary Six">Primary Six</option>
                                <option value="Jss One">Jss One</option>
                                <option value="Jss Two">Jss Two</option>
                                <option value="Jss Three">Jss Three</option>
                                <option value="SS One">SS One</option>
                                <option value="SS Two">SS Two</option>
                                <option value="SS Three">SS Three</option>
                                <option value=" ">All Levels</option>
                                </select>
                            </div>
                            </div>

                            <div className="col-md-3">
                            <div className="form-group text-left">
                            <label htmlFor="topic">Topic</label>
                            <input name="topic" type="text" className="form-control" id="topic" placeholder="Topic e.g.: Animal Science" value={topic} onChange={(e) =>{setTopic(e.target.value)}}/>
                            </div>
                            </div>
                            </div>
                            

                            <div className="form-group text-left">
                            <label htmlFor="question">Question</label>
                            <Tiny height={300} handleEditorChange={changeEditor} name="question" value={editor.question}/>
                            </div>
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="row">
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="answer">Answer</label>
                                <Tiny height={200} handleEditorChange={changeEditor} name="answer" value={editor.answer}/>
                                </div>                                    
                                </div>    
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="option_one">Option</label>
                                <Tiny height={200} handleEditorChange={changeEditor} name="option_one" value={editor.option_one}/>
                                </div>                                    
                                </div>    
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="option_two">Option</label>
                                <Tiny height={200} handleEditorChange={changeEditor} name="option_two" value={editor.option_two}/>
                                </div>                                    
                                </div>    
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="option_three">Option</label>
                                    <Tiny height={200} handleEditorChange={changeEditor} name="option_three" value={editor.option_three}/>
                                </div>                                    
                                </div>   
                            </div>   
                            </div> 
                            <div className="col-md-4">
                            <div className="form-group text-left">
                            <label htmlFor="explanation">Explanation</label>
                            <Tiny height={450} handleEditorChange={changeEditor} name="explanation" value={editor.explanation}/>
                            </div>
                            </div> 

                        </div>
        <Link data-tip="Save"className="lni-save text-30 m-2" onClick={saveQuestion} />
        <Link data-tip="My Questions" className="lni-list text-30 m-2" to="/questions"/>
        <ReactTooltip />
        </form>
</div>

<Footer/>
        </>
    );
}

export default AddQuestion;