import React, { useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import doUpload from '../Helpers/doUpload';
import updateUser from '../Helpers/updateUser';
import Button from 'react-bootstrap-button-loader'
import ReactTooltip from "react-tooltip";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { API_QB } from "../../constants/apiContants";
import swal from "sweetalert";

const Profile = (props)=> {
  let [showModal, setShowModal] = useState(false);
  let [issaving, setIssaving] = useState(false);
  let [logo, setLogo] = useState(props.user.logo);
  let [company, setCompany] = useState(props.user.company);
  let [avatar, setAvatar] = useState(props.user.avatar);
  let [name, setName] = useState(props.user.name);
  let [email, setEmail] = useState(props.user.email);
  let [phone, setPhone] = useState(props.user.phone);
  let [password, setPassword] = useState();
  let [npassword, setNpassword] = useState();
  let [cpassword, setCpassword] = useState();

  const handleClose = () => {
        setShowModal(false);
        setIssaving(false);
  }

  const updateProfile = () => {
        axios({
          url:API_QB+'update-profile',
          method:'post',
          data:{agent_id:props.user.id, name:name, passport:avatar, email:email, phone:phone},
          headers:{'Content-Type':'application/json'}
        }).then((response)=>{
          let status = (response.data.status === 'success') ? 'success':'info'
          if (response.data.status === 'success'){
              updateUser({name:name, passport:avatar, email:email, phone:phone, company:company, logo:logo})
            }
            swal('', response.data.message, status)
            handleClose();
      });
  }
  
  const changePassword = () => {
    if (npassword === cpassword){
          axios({
            url:API_QB+'change-password',
            method:'post',
            data:{agent_id:props.user.id, old_password:password, new_password:npassword},
            headers:{'Content-Type':'application/json'}
          }).then((response)=>{
            let status = (response.data.status === 'success') ? 'success':'info'
            if (response.data.status === 'success'){
            }
            swal('', response.data.message, status)
            handleClose();
        });
    }else{
      swal('', 'Passwords must match!', 'info');
    }
        
  }

  return (
    <>
    <Header title="Schooola - Profile" progress={100} />
        <div className="container">
          <h5>Profile</h5>
          <div className="row">
            <div className="col-md-4">
              <label>Logo:</label>
              <input type="file" name="logo" className="form-control" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setLogo(data.Key);
                    setIssaving(false);
                })
            })}/>
            </div>
            <div className="col-md-8">
              <label>Company:</label>
              <input name="name" value={company} className="form-control" required onChange={(e)=>{setCompany(e.target.value)}} />
            </div>
            <div className="col-md-4">
              <label>Passport:</label>
              <input type="file" name="passport" className="form-control" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setAvatar(data.Key);
                    setIssaving(false);
                })
            })}/>
            </div>
            <div className="col-md-8">
              <label>Full Name:</label>
              <input name="name" value={name} className="form-control" required onChange={(e)=>{setName(e.target.value)}} />
            </div>
            <div className="col-md-6">
              <label>Email:</label>
              <input name="email" value={email} className="form-control" required onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
            <div className="col-md-6">
              <label>Phone:</label>
              <input name="phone" value={phone} className="form-control" required onChange={(e)=>{setPhone(e.target.value)}} />
            </div>
          </div>
          <Button disabled={issaving} data-tip="Update Profile" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary" style={{marginLeft:'-15px', marginTop:'10px'}}></span>} onClick={()=>{updateProfile(); setIssaving(true)}}></Button>
          <Button data-tip="Change Password" bsStyle="default" spinColor="blue" icon={<span className="lni-lock text-30 text-primary" style={{marginLeft:'-15px', marginTop:'10px'}}></span>} onClick={()=>{setShowModal(true)}}></Button>
          <ReactTooltip/>
          <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Old Password</Form.Label>
              <Form.Control type="password" onChange={(e)=>{setPassword(e.target.value)}} />
              <Form.Text className="text-muted">
                This is to make sure you are the owner of this account!
              </Form.Text>
            </Form.Group>
          
            <Form.Group controlId="formBasicPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" onChange={(e)=>{setNpassword(e.target.value)}}/>
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control type="password" onChange={(e)=>{setCpassword(e.target.value)}}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <ReactTooltip/>
          <Button data-tip="Save changes" bsStyle="default" icon={<span className="lni-save text-30 text-primary" style={{marginLeft:'-15px', marginTop:'10px'}}></span>} onClick={()=>{changePassword(); setIssaving(true)}}/>
          <Button data-tip="Discard changes" bsStyle="default" icon={<span className="lni-cross-circle text-30 text-primary" style={{marginLeft:'-15px', marginTop:'10px'}}></span>} onClick={handleClose}/>
        </Modal.Footer>
      </Modal>
        </div>                
    <Footer />
    </>
  );
}

export default Profile;