import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { API_SRC, NAMELOGO } from '../../constants/apiContants';
import { withRouter } from "react-router-dom";
import updateUser from '../Helpers/updateUser';
import useProfile from '../Hooks/User';
import Head from '../Header/Head';
import Button from 'react-bootstrap-button-loader'

const AgentLogin = (props) => {

    const user = useProfile();
    
    let [progress, setProgress] = useState(100)
    let [error, setError] = useState(null)
    let [submitting, setSubmitting] = useState(false)
    let [phone, setPhone] = useState()
    let [password, setPassword] = useState()

    useEffect(()=>{
        if (user.email){
            props.history.push('/home')
        }    
    }, [props.history, user.email]);

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          handleSubmit();
        }
      }
    
    const handleSubmit = () => {
        setSubmitting(true)
        setError(null);
        setProgress(80)
        axios({
            method:'post',
            url:API_SRC+'agent-login',
            headers:{'Content-Type':'application/json'},
            data:{"login_id":phone, "password":password}
        }).then((response)=>{
            if (response.data.id) {
                updateUser({id:response.data.id, agent_id:response.data.agent_id, name:response.data.name, passport:response.data.passport, email:response.data.email, phone:response.data.phone, company:response.data.company, logo:response.data.logo})
                // props.history.push('/home')
                window.location.reload()
            } else {
                setError("Invalid credentials!");
                setSubmitting(false);
                setProgress(100);
            }
        }).catch(()=>{
            setError("Invalid credentials!");
            setSubmitting(false);
            setProgress(100);
        })
    
    }
    
    return (
        
      <>
      <Head title={'Schoola - Login'} progress={progress}/>
    <div className="card col-12 col-lg-4 login-card hv-center mt-100 p-30">
    <img alt="schoola logo" src={NAMELOGO} width="100px"/>
    <div className="alert alert-danger mt-2" style={{display: error ? 'block' : 'none' }} role="alert">{error}</div>
        <form className="m-2" onKeyDown={(e)=>{handleKeyPress(e)}}>
            <div className="form-group text-left">
                <label htmlFor="phone">Login ID</label>
                <input type="text" className="form-control" id="phone" placeholder="Phone Number or Email" value={phone} onChange={(e)=>{setPhone(e.target.value)}}/>
            </div>
            <div className="form-group text-left">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
            </div>
            
            <Button data-tip="Login" loading={submitting} bsStyle="default" spinColor="blue" icon={<span className="lni-check-mark-circle text-30 text-primary pb-3"></span>} onClick={()=>{handleSubmit()}}></Button>
        </form>     
    </div>
            
            </>
    )

    
}

export default withRouter(AgentLogin);