import React from 'react';
// import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import PrivateRoute from './utils/PrivateRoute';
import AgentLogin from './Components/LoginForm/AgentLogin';
import AgentRegister from './Components/RegistrationForm/AgentRegister';
// import ForgetPassword from './Components/ForgetPassword/ForgetPassword';
import Home from './Components/Home/Home';
import Schools from './Components/Schools/Schools';
import SchoolDetail from './Components/Schools/SchoolDetail';

// import AddSchool from './Components/AddSchool/AddSchool';
import CreateSchool from './Components/AddSchool/CreateSchool';
import FundWallet from './Components/Wallet/FundWallet';
import VerifyPayment from './Components/Wallet/VerifyPayment';
import Tournament from './Components/Tournament/Tournament';
import Bank from './Components/Questions/Bank';
import AddQuestion from './Components/Questions/AddQuestion';
import EditQuestion from './Components/Questions/EditQuestion';
import BulkQuestions from './Components/Questions/BulkQuestions';
import Questions from './Components/Questions/Questions';
import Tournaments from './Components/Tournament/Tournaments';
import AddTournament from './Components/Tournament/AddTournament';
import Singletournament from './Components/Tournament/Singletournament';
import AddQuiz from './Components/Tournament/AddQuiz';
import Quizzes from './Components/Tournament/Quizzes';
import Profile from './Components/Profile/Profile';
import Licence from './Components/Licence/Licence';
import AddAgent from './Components/Subagents/AddAgent';
import useProfile from './Components/Hooks/User';

function App() {
  const user = useProfile();
  return (
    <Router>
    <div className="App">
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
            <AgentLogin/>
            </Route>
            <Route path="/agent-register">
              <AgentRegister/>
            </Route>
            <Route path="/agent-login">
              <AgentLogin/>
            </Route>
            {/* <Route path="/agent-forget-password">
              <ForgetPassword/>
            </Route> */}
        
            <PrivateRoute path="/home">
              <Home user={user}/>
            </PrivateRoute>
        
            <PrivateRoute path="/schools">
              <Schools user={user} />
            </PrivateRoute>

            <PrivateRoute path="/new-school">
              <CreateSchool user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/licence">
              <Licence user={user} />
            </PrivateRoute>

            <PrivateRoute path="/fund-wallet">
              <FundWallet user={user}/>
            </PrivateRoute>

            <PrivateRoute path="/school-detail/:id">
              <SchoolDetail user={user} />
            </PrivateRoute>

            <PrivateRoute path="/verify-payment/:id">
              <VerifyPayment user={user} />
            </PrivateRoute>

            <PrivateRoute path="/tournament">
              <Tournament user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/tournament-details/:id">
              <Singletournament user={user}/>
            </PrivateRoute>

            <PrivateRoute path="/questions-bank">
              <Bank user={user} />
            </PrivateRoute>

            <PrivateRoute path="/add-question">
              <AddQuestion user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/edit-question/:id">
              <EditQuestion user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/bulk-questions">
              <BulkQuestions user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/questions">
              <Questions user={user} />
            </PrivateRoute>

            <PrivateRoute path="/tournaments">
              <Tournaments user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/quizzes">
              <Quizzes user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/add-tournament">
              <AddTournament user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/agents">
              <AddAgent user={user} />
            </PrivateRoute>


            <PrivateRoute path="/add-quiz">
              <AddQuiz user={user} />
            </PrivateRoute>
            
            <PrivateRoute path="/profile">
              <Profile user={user} />
            </PrivateRoute>
          </Switch>
        </div>
    </div>
    </Router>
  );
}

export default App;
