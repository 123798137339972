const updateUser = (profile)=>{
    Object.keys(profile).map(function(key, index) {
      if (profile[key] === null || profile[key] === undefined){

        localStorage.removeItem(key);

      }
      else{
        
        localStorage.setItem(key, profile[key]);
      }

        return profile;
      });
      return profile;
}

export default updateUser;