import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB, SUCCESS_SOUND } from '../../constants/apiContants';
import Tiny from '../Editor/Tiny'
import ReactTooltip from 'react-tooltip'
import { Link, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import toTitleCase from '../Helpers/changeCase';

const EditQuestion = (props) => {
    let audio = new Audio(SUCCESS_SOUND);
    let [banks, setBanks] =  useState([]);
    let [progress, setProgress] =  useState(100);
    let agent_id = props.user.agent_id ? props.user.agent_id : props.user.id
    let [bank, setBank] = useState()
    let [subject, setSubject] = useState()
    let [level, setLevel] = useState()
    let [topic, setTopic] = useState()
    let [editor, setEditor] = useState({question:'', answer:'', option1:'', option2:'', option3:'', explanation:''})

    let { id } = useParams()

    useEffect(() => {
        setProgress(20);
        
        axios({
            method:'post',
            url: API_QB + `agent-banks`,
            data: { "agent_id": agent_id },
            headers: {'Content-Type':'application/json'}
        }).then((response)=>{
            setProgress(50)
            if (response.data) {
                setProgress(100)
                setBanks(Array.from(response.data))
            }
        }).catch(()=>{});
        
        axios({
            method:'get',
            url: `${API_QB}get-question?id=${id}`
        }).then((response)=>{
            setProgress(50)
            if (response.data) {
                let q = response.data;
                let toEdit = {question:q.question, explanation:q.html_reason}
                let count = 0;
                q.options.map(option =>{
                    if (option.is_answer){
                        return (
                            toEdit['answer'] = option.html
                        )   
                    }else{
                        count++
                        return (
                            toEdit['option'+count] = option.html
                        )   
                    }
                })
                setBank(toTitleCase(q.bank))
                setSubject(toTitleCase(q.subject))
                setLevel(q.level?q.level.toLowerCase():'')
                setTopic(toTitleCase(q.topic))
                setEditor(toEdit)
            }
        }).catch(()=>{})
    }, [agent_id, id])



    const changeEditor = (content, editor) => {
        // this.setState({[editor.id]:editor.getContent()})
        setEditor(prevState => ({ ...prevState, [editor.id]:editor.getContent() }));
      }

      const approveQuestion = () => {
        axios({
            url:API_QB+'approve-questions',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{'questions':[id]}
        }).then(()=>{
            audio.play()
        });
    }

    const saveQuestion = () => {
        let options = []
        Array(3).fill(0).map((_, i) =>{
            if ( editor[`option${i+1}`] ){
                options.push(editor[`option${i+1}`])
            }
            return null;
        })
        if (bank && subject && topic && editor.question && editor.answer){
        setProgress(20)
        const payload = {
            bank:bank,
            subject: subject,
            level:level,
            topic: topic,
            question:editor.question,
            answer:editor.answer,
            options:options,
            explanation:editor.explanation,
            agent_id:agent_id,
            question_id:id
        }

        axios({
            method:'post',
            url:`${API_QB}update-question`,
            data:payload,
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            setProgress(50)
            if (response.data) {
                setProgress(100)
                // setEditor({question:'', answer:'', option1:'', option2:'', option3:'', explanation:''})
                audio.play()
                }
        }).catch(()=>{});
    }
        else{
            swal('Oops!', 'You must add at least an answer and an explation', 'info');
        }

    }

    return (
        <>
    <Header title="Schoola - Edit Questions" progress={progress} />
    <div className="container">
        <h5>Edit Question</h5>
        <form>
                    
                            
                    <div className="row">
                    <div className="col-md-3">
                    <div className="form-group text-left">
                            <label htmlFor="bank">Select Bank</label>
                            <select type="text" name="bank" className="form-control" id="bank" placeholder="Bank" value={bank} onChange={(e) =>{setBank(e.target.value)}}>
                                <option>Select Bank</option>
                                {
                                    banks.map(bank => {
                                        return (  <option key={bank.id} value={bank.name}>{bank.name}</option>)
                                    })
                                }
                                </select>
                            </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group text-left">
                            <label htmlFor="subject">Subject</label>
                            <input type="text" name="subject" className="form-control"  id="subject" placeholder="Subject e.g.: Agricalture" value={subject} onChange={(e) =>{setSubject(e.target.value)}}/>
                            </div>
                            </div>
                            <div className="col-md-3">
                            <div className="form-group text-left">
                            <label htmlFor="level">Level</label>
                            <select type="text" name="level" className="form-control" id="level" value={level} onChange={(e) =>{setLevel(e.target.value)}}>
                                <option>Select Level</option>
                                <option value="">All Levels</option>
                                <option value="primary one">Primary One</option>
                                <option value="primary two">Primary Two</option>
                                <option value="primary three">Primary Three</option>
                                <option value="primary four">Primary Four</option>
                                <option value="primary five">Primary Five</option>
                                <option value="primary six">Primary Six</option>
                                <option value="jss one">Jss One</option>
                                <option value="jss two">Jss Two</option>
                                <option value="jss three">Jss Three</option>
                                <option value="ss one">SS One</option>
                                <option value="ss two">SS Two</option>
                                <option value="ss three">SS Three</option>
                                </select>
                            </div>
                            </div>

                            <div className="col-md-3">
                            <div className="form-group text-left">
                            <label htmlFor="topic">Topic</label>
                            <input name="topic" type="text" className="form-control" id="topic" placeholder="Topic e.g.: Animal Science" value={topic} onChange={(e) =>{setTopic(e.target.value)}}/>
                            </div>
                            </div>
                            </div>
                            

                            <div className="form-group text-left">
                            <label htmlFor="question">Question</label>
                            <Tiny height={300} handleEditorChange={changeEditor} name="question" value={editor.question}/>
                            </div>
                        <div className="row">
                            
                            <div className="col-md-8">
                                <div className="row">
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="answer">Answer</label>
                                <Tiny height={200} handleEditorChange={changeEditor} name="answer" value={editor.answer}/>
                                </div>                                    
                                </div>    
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="option1">Option</label>
                                <Tiny height={200} handleEditorChange={changeEditor} name="option1" value={editor.option1}/>
                                </div>                                    
                                </div>    
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="option3">Option</label>
                                <Tiny height={200} handleEditorChange={changeEditor} name="option2" value={editor.option2}/>
                                </div>                                    
                                </div>    
                                <div className="col-md-6">
                                <div className="form-group text-left">
                                <label htmlFor="option3">Option</label>
                                    <Tiny height={200} handleEditorChange={changeEditor} name="option3" value={editor.option3}/>
                                </div>                                    
                                </div>   
                            </div>   
                            </div> 
                            <div className="col-md-4">
                            <div className="form-group text-left">
                            <label htmlFor="explanation">Explanation</label>
                            <Tiny height={450} handleEditorChange={changeEditor} name="explanation" value={editor.explanation}/>
                            </div>
                            </div> 

                        </div>
                        <Link data-tip="Save"className="lni-save text-30 m-5" onClick={saveQuestion} />
                        <Link data-tip="Toggle Question Approval" className="lni-shuffle text-30 m-5" onClick={approveQuestion}/>
                        <Link data-tip="My Questions" className="lni-list text-30 m-5" to="/questions"/>
        <ReactTooltip />
        </form>
</div>

<Footer/>
        </>
    );
}

export default EditQuestion;