import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from "react-router-dom";
import {API_SRC, BOLMAPAY_API} from '../../constants/apiContants';

class VerifyPayment extends Component {
    constructor(props) {
        super(props);

    const fullname = localStorage.getItem('name')
    const userid = localStorage.getItem('id')
    const phone = localStorage.getItem('phone')
    const email = localStorage.getItem('email')
    const order_id = localStorage.getItem('bolma_orderid')


    this.state = {
                // amount : "",
                userid : userid,
                phone : phone,
                fullname : fullname,
                email : email,
                orderid: order_id,
                successMessage: null,
                progress: 100
    }
    }

    componentDidMount() {
        this.verifyref()
        // alert(this.state.orderid);
    }

    UpdateUserWallet = (amount) => {
        var self = this;
        const payload = {
            "agent_id": Number(this.state.userid),
            "amount": Number(amount),
            "narration": "Bolmapay Transfer",
            "is_inflow": true,
        }
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Credentials":'true'
            }
        };
        axios.post(API_SRC + 'agent-transaction', payload, axiosConfig)
            .then(function (response) {
                self.setState({ progress: 50 })
                console.log(response);
                if (response.data) {
                    self.setState({ school_name: response.data.name, progress: 100})
                    self.redirectToHome();
                }else {
                  //   props.showError(response.data.message);
                }
            })
            .catch(function (error) {
                // console.log(error);
            });
    }
    verifyref = () => {
    var self = this;
        self.setState(prevState => ({
            ...prevState,
            'successMessage' : 'Please wait...'
        }))
        self.setState({progress: 20})
            let axiosConfig = {
                headers: {
                    'x-access-token': BOLMAPAY_API,
                    'Content-Type': 'application/json;charset=UTF-8',
                    "Access-Control-Allow-Origin": "*",
                }
            };
            
            self.setState({progress: 30})
            axios.get('https://api.bolmapay.com/api/auth/orders/status/' + self.state.orderid, axiosConfig)
                .then(function (response) {
                    console.log(response.data)
                    if(response.data.order_status === "PAID"){
                        self.setState({ progress: 100 })
                        
                        // self.BuyAir(amount, phone, telco);
                        self.setState(prevState => ({
                                ...prevState,
                                'successMessage' : 'Please wait...'
                        })) 

                        var amount = response.data.amount;
                        self.UpdateUserWallet(amount);
                            // window.location.replace(response.data.url)


                    }
                    else{
                        self.props.showError(response.data.message);
                    } 
                })
                .catch(function (error) {
                    // console.log(error);
                });
    }


     redirectToHome = () => {
        window.location = "/home";
    }


    render() {
        return (
            <div>

            </div>
        );
    }
}

VerifyPayment.propTypes = {

};

export default withRouter(VerifyPayment);