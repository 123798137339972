import React from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY, API_QB} from '../../constants/apiContants';

const Tiny = (props)=>{
    return (
        <Editor
        apiKey={TINYMCE_KEY}
         initialValue=""
         id={props.name}
         value={props.value}
         init={{
          images_upload_url: API_QB+'upload-image',
          automatic_uploads: true,
           height: props.height,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap print preview anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code help wordcount'
           ],
           toolbar:
             'image| superscript subscript | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',

             paste_data_images: true,
             branding: false,
         }}
         onEditorChange={props.handleEditorChange}
       />
    )
}

export default Tiny;