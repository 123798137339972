import React, { Component } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import { Link } from "react-router-dom";




class Tournament extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <>

        <Header title="Schoola - Tournaments" progress={100} />
                
        <div className="page-content-wrapper">
      <div className="container">
        <div className="checkout-wrapper-area pt-3">
          <div className="choose-payment-method">
            {/* <h6 className="mb-3 text-center">Choose Payment Method</h6> */}
            <div className="row">
              <div className="col-6">
                <div className="single-payment-method"><Link className="credit-card" to="/questions-bank"><i className="lni-question-circle"></i>
                    <h6>Questions Bank</h6></Link></div>
              </div>
              <div className="col-6">
                <div className="single-payment-method"><Link className="credit-card" to="/add-question"><i className="lni-question-circle"></i>
                    <h6>Add Question</h6></Link></div>
              </div>
              <div className="col-6">
                <div className="single-payment-method"><Link className="credit-card" to="/bulk-questions"><i className="lni-question-circle"></i>
                    <h6>Bulk Questions</h6></Link></div>
              </div>
              <div className="col-6">
                <div className="single-payment-method"><Link className="bank" to="/questions"><i className="lni-question-circle"></i>
                    <h6>Questions</h6></Link></div>
              </div>
              <div className="col-6">
                <div className="single-payment-method"><Link className="paypal" to="/tournaments"><i className="lni-cup"></i>
                    <h6>Tournaments</h6></Link></div>
              </div>
              <div className="col-6">
                <div className="single-payment-method"><Link className="paypal" to="/add-quiz"><i className="lni-add-file"></i>
                    <h6>Quizzes</h6></Link></div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

        <Footer/>
            </>
        );
    }
}

Tournament.propTypes = {

};

export default Tournament;
