import React from 'react';
import LoadinBar from 'react-top-loading-bar';
import Helmet from 'react-helmet';
import { useState } from 'react';


const Head = (props) => {
   
  let [progress, setProgress] = useState(props.progress);
    return (

      <>
      <Helmet>
      <title>{props.title}</title>
      </Helmet>
      <LoadinBar color='#f11946' progress={progress} onLoaderFinished={() => setProgress(0)}/>
      </>

    )
}


export default Head;