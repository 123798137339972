import React, {useState} from 'react';
import axios from 'axios';
import { API_SRC, NAMELOGO } from '../../constants/apiContants';
import Head from '../Header/Head';
import Button from 'react-bootstrap-button-loader'
import doUpload from '../Helpers/doUpload';

const AgentRegister = () => {
    let [name, setName] = useState();
    let [email, setEmail] = useState();
    let [phone, setPhone] = useState();
    let [gender, setGender] = useState();
    let [password, setPassword] = useState();
    let [passport, setPassport] = useState();
    let [successMessage, setSuccessMessage] = useState();
    let [progress, setProgress] = useState(100);
    let [issaving, setIssaving] = useState(false);
    
    const doSubmit = () => {
        setProgress(50)
        setIssaving(true)

        setTimeout(()=>{
            axios({
                url:API_SRC+'new-agent',
                method:'post',
                headers:{'Content-Type':'application/json'},
                data: {name:name, email:email, phone:phone, gender:gender, password:password, passport:passport}
            }).then((response) =>{
                if (response.data.id) {
                    setSuccessMessage('New partner registered successfully!');
                }else{
                    setSuccessMessage('Partner with same credentials already exist!');
                }
            }).catch(()=>{
                setSuccessMessage('There was an error registering new partner!');
            });
            setProgress(100)
            setIssaving(false)
        }, 1000)
    }
   
    return (
        <>
        <Head title={'Schoola - Register'} progress={progress}/>
            
        
        <div className="card col-12 col-lg-4 login-card hv-center mt-100 p-30">
        <img className="mb-5" alt="schoola logo" src={NAMELOGO} width="100px" />
         <form className="m-2">
            <div className="form-group text-left">
            <input type="file" name="logo" className="form-control" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setPassport(data.Key);
                    setIssaving(false);
                })
            })}/>
            </div>

            <div className="form-group text-left">
                <label htmlFor="fullname">Fullname</label>
                <input type="text" className="form-control" id="fullname" placeholder="e.g. Haruna Tula" value={name} onChange={(e)=>{setName(e.target.value)}} />
            </div>


            <div className="form-group text-left">
            <label htmlFor="gender">Gender</label>
            <select className="form-control" name="gender" id="gender" onChange={(e)=>{setGender(e.target.value)}} >
                <option>Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
            </select>
            </div>       
            <div className="form-group text-left">
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" className="form-control" id="phone"  placeholder="Phone Number" value={phone} onChange={(e)=>{setPhone(e.target.value)}} />
            </div>
                        
            <div className="form-group text-left">
                <label htmlFor="email">Email address</label>
                <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
            </div>
                        
            <div className="form-group text-left">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}} />
            </div>
            <div className="alert alert-success mt-2 mb-5" style={{display: successMessage ? 'block' : 'none' }} role="alert">{successMessage}</div>
            <Button data-tip="Login" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-check-mark-circle text-30 text-primary pb-3"></span>} onClick={()=>{doSubmit()}}></Button>
            </form>
            </div>
            </>
    )
}


export default AgentRegister;