import { SPACE_ENDPOINT, SPACE_BUCKET_NAME, SPACE_ACCESS_ID, SPACE_SECRET_KEY } from "../../constants/apiContants";
const AWS = require('aws-sdk');
const spacesEndpoint = new AWS.Endpoint(SPACE_ENDPOINT); // replace with your region endpoint

const doUpload = (file) => {
  // Create a new instance of the S3 service
  const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: SPACE_ACCESS_ID,
    secretAccessKey: SPACE_SECRET_KEY
  });

  // Define the parameters for the upload to DigitalOcean Spaces including the folder name and set file to public and a random name for the file
  const filename = `schoola/${file.name.replace(/\s/g, '')}`;
  const params = {
    Bucket: SPACE_BUCKET_NAME,
    Key: filename,
    Body: file,
    ACL: 'public-read'
  };

  // Upload the file to DigitalOcean Spaces and set it to public and return a promise
  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

export default doUpload;