import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB } from '../../constants/apiContants';
import Tiny from '../Editor/Tiny'
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import doUpload from '../Helpers/doUpload';
// import debitWallet from '../Helpers/debitWallet';
import Button from 'react-bootstrap-button-loader';
import moment from 'moment';
import Select from 'react-select'

const AddTournament = (props)=>{
    let [issaving, setIssaving] = useState(false);
    // let [isdebited, setIsdebited] = useState(false);
    let [poster, setPoster] = useState('tournament.jpg');
    let [bank, setBank] = useState('private');
    let [name, setName] = useState('');
    let [description, setDescription] = useState('');
    let [type, setType] = useState('rapid');
    let [fee, setFee] = useState(1000);
    let [accesibility, setAccessibility] = useState('public');
    let [starts, setStarts] = useState('');
    let [ends, setEnds] = useState('');
    let [subjects, setSubjects] = useState('');
    let [levels, setlevels] = useState('');
    let [selectBanks, setSelectbanks] = useState([]);
    let [selectSubjects, setSelectSubjects] = useState([]);
    let [selectLevels, setSelectLevels] = useState([]);
    const selectStyle = {input: styles => ({borderColor: "#ebebeb", height: "45px", padding: "12px 20px", fontSize: "14px"})}

    useEffect(() => {
        // get banks
        axios({
            url:API_QB+'agent-banks',
            method:'post',
            data:{agent_id:props.user.id},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                let _banks = []
                response.data.map(bank => {
                    _banks.push({label: bank.name, value:bank.name})
                    return _banks;
                });
                setSelectbanks(_banks)
            }
        });
        // get subjects
        // get levels
        axios({
            url:API_QB+'get-subjects',
            method:'post',
            headers: {'Content-Type':'application/json'},
            data: {agent_id:props.user.id}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                let _subjects = []
                response.data.map(question => {
                    return _subjects.push({value:question.subject, label:question.subject})
                });
                setSelectSubjects(_subjects)
            }
        });
        // get levels
        axios({
            url:API_QB+'get-levels',
            method:'post',
            headers: {'Content-Type':'application/json'},
            data: {agent_id:props.user.id}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                let _levels = []
                response.data.map(question => {
                    return _levels.push({value:question.level, label:question.level})
                });
                setSelectLevels(_levels)
            }
        });
    }, [props.user.id]);

    
    const validateTiming = (starts, ends) =>{
        let start = moment(starts);
        let end = moment(ends);
        let duration = moment.duration(end.diff(start));
        let difference = duration.asMinutes();
        if (difference<59){
            swal('', 'There should be atleast an hour difference between when a tournament starts and when it ends');
            setEnds(start.add(60, 'minutes').format('YYYY-MM-DDTHH:MM'))
        }
    }

    const filterSelected = (arrayobject)=>{
        let list = [];
        arrayobject.map(selected=>{
            return list.push(selected.value);
        });
        return list.join();
    }

    const doSubmit = () => {
        let ispublic = (filterSelected([accesibility])==='public')?true:false;
        setIssaving(true);
        axios({
            url:API_QB+'create-tournament',
            method:'post',
            data:{bank:filterSelected([bank]), poster:poster, name:name, description:description, type:filterSelected([type]), subjects:filterSelected(subjects), levels:filterSelected(levels), fee:fee, is_public:ispublic, starts:starts, ends:ends, agent_id:props.user.id},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            if (response.data.id){
                setIssaving(false);
                swal('', 'Tournament created successfully!', 'success')
            }else{
                setIssaving(false);
                swal('', 'There was an error creating tournament!', 'info')
            }
        })
    }


    return (
        <>
        <Header/>
        <div className="container">
        <h5>Host a Tournament</h5>
        <div className="row">
        <div className="form-group col-md-4">
            <label>Poster</label>
            <input name="poster" type="file" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setPoster(data.Key);
                    setIssaving(false);
                })
            })} className="form-control" required />
        </div>
        <div className="form-group col-md-8">
            <label>Title</label>
            <input type="text" name="name" value={name} onChange={(e)=>{setName(e.target.value)}} className="form-control" placeholder="e.g.: Awesome Spelling Bee" required />
        </div>
        <div className="form-group col-md-12">
            <label>Description</label>
            <Tiny name="description" value={description} height={200} handleEditorChange={(content, editor) => {setDescription(editor.getContent())}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Entry Fee</label>
            <input type="number" name="fee" value={fee} onChange={(e)=>{setFee(e.target.value)}} className="form-control" placeholder="e.g.: 1500" required />
        </div>
        <div className="form-group col-md-4">
            <label>Subjects</label>
            <Select styles={selectStyle} isMulti options={selectSubjects} onChange={(e)=>{setSubjects(e)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Levels</label>
            <Select styles={selectStyle} isMulti options={selectLevels} onChange={(e)=>{setlevels(e)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Tournament Type</label>
            <Select styles={selectStyle} options={[{label:'Rapid', value:'rapid'}, {label:'League', value:'league'}, {label:'Quiz', value:'quiz'}, {label:'Practice', value:'practice'}]} onChange={(e)=>{setType(e)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Question Bank</label>
            <Select styles={selectStyle} options={selectBanks} onChange={(e)=>{setBank(e)}}/>
        </div>
        <div className="form-group col-md-4">
            <label>Accesibility</label>
            <Select styles={selectStyle} options={[{label:<span>Private <span className='lni-lock text-danger'/></span>, value:'private'}, {label:<span>Public <span className='lni-unlock text-success'/></span>, value:'public'}]} onChange={(e)=>{setAccessibility(e)}}/>
        </div>
        <div className="form-group col-md-6">
            <label>Starting Date & Time</label>
            <input type="datetime-local" name="starts" value={starts} onChange={(e)=>{setStarts(e.target.value); validateTiming(e.target.value, ends)}} className="form-control" required />
        </div>
        <div className="form-group col-md-6">
            <label>Ending Date & Time</label>
            <input type="datetime-local" name="ends" value={ends} onChange={(e)=>{setEnds(e.target.value); validateTiming(starts, e.target.value)}} className="form-control" required />
        </div>
        <div className="form-group col-md-6">
            <ReactTooltip/>
            <Button data-tip="Save Tournament" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary pb-3"></span>} onClick={doSubmit}></Button>
            <Link to="/tournaments" data-tip="My Tournaments" className="lni-list text-30 ml-3 text-primary" />
        </div>
        </div>
        </div>
          
        <Footer/>
        </>
    )
}
export default AddTournament;