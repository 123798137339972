import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB, SUCCESS_SOUND } from '../../constants/apiContants';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';


const Questions = (props) => {
    let columns = [
        {
          name: 'Subject',
          selector: 'subject',
          sortable: true,
        },
        {
          name: 'Level',
          selector: 'level',
          sortable: true,
        },
        {
          name: 'Question',
          selector: 'text',
          sortable: true,
          wrap:true,
        },
        {
          name: 'Topic',
          selector: 'topic',
          sortable: true,
        },
        {
          name: 'Bank',
          selector: 'bank',
          sortable: true,
        },

        {
          name: 'Edit',
          cell: row => <div data-tag="allowRowEvents"> <Link onClick={()=>{setEdit(true); setEditor(`edit-question/${row.id}`)}}><span className="lni-write"></span></Link> </div>,
        },
        {
          name: 'Approval Status',
          cell: row => <div data-tag="allowRowEvents"> {row.is_approved ? <span className="lni-thumbs-up"></span> : <span className="lni-thumbs-down"></span>} </div>,
        }
        
      ];

      let [questions, setQuestions] = useState([])
      let [table, setTable] = useState([])
      let agent_id = props.user.agent_id ? props.user.agent_id : props.user.id
      let [progress, setProgress] = useState(100)
      let [selectedRows, setSelectedRows] = useState()
      let [doRefresh, setDoRefresh] = useState(false)
      let [edit, setEdit] = useState(false)
      let [editor, setEditor] = useState('')
      let audio = new Audio(SUCCESS_SOUND);


      useEffect(()=>{
        setProgress(20)
        axios({
            url:API_QB+'agent-questions',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{"agent_id":agent_id}
        }).then((response) =>{
            setProgress(100);
            if(typeof(response.data) === 'object'){
                setQuestions(response.data)
                setTable(response.data)
            }
        })
        .catch((error) =>{});
      }, [agent_id, doRefresh])

     const filterTable = (event)=>{
        let term = event.target.value;
        let result = questions.filter(function(object) {
            return (object.subject.indexOf(term) > -1 || object.level.indexOf(term) > -1 || object.topic.indexOf(term) > -1 || object.question.indexOf(term) > -1 || object.bank.indexOf(term) > -1);
        });
        setTable(result);
    }

    const updateState = state => {
        setSelectedRows(state.selectedRows)
      }

    const deleteQuestions = () => {
        let questionids = [];
        selectedRows.map(question=>{
            questionids.push(question.id);
            return questionids;
        });
  
        axios({
            url:API_QB+'delete-questions',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{'questions':questionids}
        }).then(()=>{
            audio.play()
            setDoRefresh(!doRefresh);
        });
    }
    
    const approveQuestions = () => {
        let questionids = [];
        selectedRows.map(question=>{
            questionids.push(question.id);
            return questionids;
        });
  
        axios({
            url:API_QB+'approve-questions',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{'questions':questionids}
        }).then(()=>{
            audio.play()
            setDoRefresh(!doRefresh);
        });
    }

    return (
        <>
            <Header title="Schooola - Question Bank" progress={progress} />
            
            <h5>My Question</h5>
            <div className="container">
            <div className="row">
            <div className="col-md-1">
            <Link data-tip="New Question" className="lni-write text-30 m-2" to="/add-question"/>
            <br/>
            <br/>
            <Link data-tip="Bulk Question" className="lni-files text-30 m-2" to="/bulk-questions"/>
            <ReactTooltip/>
            </div>
            <div className="card col-md-11">
            <DataTable title="Questions in Bank" columns={columns} data={table} onSelectedRowsChange={updateState} Clicked contextActions={<span><Link to="#" title="Delete Questions" className="lni-trash m-5 text-primary" onClick={deleteQuestions}/><Link to="#" title="Toggle Questions Approval" className="lni-shuffle m-5 text-primary" onClick={approveQuestions}/> </span>} contextMessage={{ singular: 'question', plural: 'questions', message: 'selected' }} pagination subHeader subHeaderComponent={<input type="text" placeholder="search" className="form-control" onChange={filterTable}/>} selectableRows persistTableHead/>
            </div>                
            </div>
            </div>
            <Footer />

            <Modal show={edit} size="xl" onHide={()=>{setEdit(false)}}>
              <Modal.Header closeButton>
                <Modal.Title>Question Editor</Modal.Title>
              </Modal.Header>
                <iframe title="editor" src={editor} style={{height:'600px'}} />
            </Modal>
  
        </>
    );
}




export default Questions;