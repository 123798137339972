import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB, SUCCESS_SOUND } from '../../constants/apiContants';
// import Tiny from '../Editor/Tiny'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Button from 'react-bootstrap-button-loader'

const BulkQuestions = (props) => {
    let audio = new Audio(SUCCESS_SOUND);
    let [banks, setBanks] =  useState([]);
    let [progress, setProgress] =  useState(100);
    let agent_id = props.user.agent_id ? props.user.agent_id : props.user.id
    let [bank, setBank] = useState()
    let [subject, setSubject] = useState()
    let [level, setLevel] = useState()
    let [topic, setTopic] = useState()
    let [file, setFile] = useState()
    let [hasEquations, setHasEquations] = useState(false)
    let [submitting, setSubmitting] = useState(false)
    // let [editor, setEditor] = useState({question:'', answer:'', option_one:'', option_two:'', option_three:'', explanation:''})


    useEffect(() => {
        setProgress(20);
        
        axios({
            method:'post',
            url: API_QB + `agent-banks`,
            data: { "agent_id": agent_id },
            headers: {'Content-Type':'application/json'}
        }).then((response)=>{
            setProgress(50)
            if (response.data) {
                setProgress(100)
                setBanks(Array.from(response.data))
            }
        }).catch(()=>{})
    }, [agent_id])



    // const changeEditor = (content, editor) => {
    //     // this.setState({[editor.id]:editor.getContent()})
    //     setEditor(prevState => ({ ...prevState, [editor.id]:editor.getContent() }));
    //   }

    const saveQuestion = () => {
        if (bank && subject && topic && file){
                let formData = new FormData();
                formData.append("document", file);
            axios({
                method:'post',
                url:`${API_QB}bulk-questions?bank=${bank}&subject=${subject}&level=${level}&topic=${topic}&agent_id=${agent_id}&has_equations=${hasEquations}`,
                data:formData,
                headers:{'Content-Type':'multipart/form-data'}
            }).then((response)=>{
                if (response.data && response.data.status === 'success') {
                    audio.play()
                    }else{
                        swal('', 'There was an error uploading questions', 'warning')
                    }
            })
    }
        else {   
            swal('Oops!', 'Operation could not be completed!', 'info');
        }

        setSubmitting(false);


    }

    return (
        <>
    <Header title="Schoola - Add Questions" progress={progress} />
    <div className="container">
        <h5>Add Bulk Questions</h5>
        <form>
                    
                            
                    <div className="row">
                    <div className="col-md-3">
                    <div className="form-group text-left">
                            <label htmlFor="bank">Select Bank</label>
                            <select type="text" name="bank" className="form-control" id="bank" placeholder="Bank" value={bank} onChange={(e) =>{setBank(e.target.value)}}>
                                <option>Select Bank</option>
                                {
                                    banks.map(bank => {
                                        return (  <option key={bank.id} value={bank.name}>{bank.name}</option>)
                                    })
                                }
                                </select>
                            </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group text-left">
                        <label htmlFor="subject">Subject</label>
                        <input type="text" name="subject" className="form-control"  id="subject" placeholder="Subject e.g.: Agricalture" value={subject} onChange={(e) =>{setSubject(e.target.value)}}/>
                        </div>
                    </div>
                            <div className="col-md-3">
                            <div className="form-group text-left">
                            <label htmlFor="level">Level</label>
                            <select type="text" name="level" className="form-control" id="level" value={level} onChange={(e) =>{setLevel(e.target.value)}}>
                                <option>Select Level</option>
                                <option value="">All Levels</option>
                                <option value="Primary One">Primary One</option>
                                <option value="Primary Two">Primary Two</option>
                                <option value="Primary Three">Primary Three</option>
                                <option value="Primary Four">Primary Four</option>
                                <option value="Primary Five">Primary Five</option>
                                <option value="Primary Six">Primary Six</option>
                                <option value="Jss One">Jss One</option>
                                <option value="Jss Two">Jss Two</option>
                                <option value="Jss Three">Jss Three</option>
                                <option value="SS One">SS One</option>
                                <option value="SS Two">SS Two</option>
                                <option value="SS Three">SS Three</option>
                                </select>
                            </div>
                            </div>

                            <div className="col-md-3">
                                <div className="form-group text-left">
                                <label htmlFor="topic">Topic</label>
                                <input name="topic" type="text" className="form-control" id="topic" placeholder="Topic e.g.: Animal Science" value={topic} onChange={(e) =>{setTopic(e.target.value)}}/>
                                </div>
                            </div>
                            
                            
                            <div className="col-md-6">
                            <div className="form-group text-left">
                            <label htmlFor="question">MS Word File (.docx)</label>
                            <input name="file" type="file" onChange={(e)=> { setFile(e.target.files[0])} } className="form-control" required />
                            <div style={{ height: "15px"}}>
                            <label htmlFor="has_equations">Your file has equations?</label>
                            <input style={{width:"20px", marginTop:"-20px"}} name="has_equations" type="checkbox" onChange={(e)=> { setHasEquations(!hasEquations)} } className="form-control" required />
                            </div>
                            </div>
                            </div>
                            
                            <div className="col-md-6">
                            <div className="form-group text-left">
                            <label>Accepted Style</label>
                            <img width="540" src="https://res.cloudinary.com/mti-cloud/image/upload/v1616552046/Screen_Shot_2021-03-24_at_2.58.17_AM_zaq5sz.png" alt="" />
                            </div>
                            </div>
                        </div>
        
        <Button data-tip="Upload Questions" loading={submitting} bsStyle="default" spinColor="blue" icon={<span className="lni-save m-2 pb-15 text-30 text-primary"></span>} onClick={()=>{saveQuestion(); setSubmitting(true)}}></Button>
        <Link data-tip="My Questions" className="lni-list text-30 m-2" to="/questions"/>
        <ReactTooltip />
        </form>
</div>

<Footer/>
        </>
    );
}

export default BulkQuestions;