import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB } from '../../constants/apiContants';
import swal from 'sweetalert'
import ReactTooltip from 'react-tooltip';
import Button from 'react-bootstrap-button-loader'

const Bank = (props) => {
    let [bank, setBank] = useState();
    let [banks, setBanks] = useState([]);
    let [issaving, setIssaving] = useState(false);

    useEffect(()=>{
        axios({
          url:API_QB+'agent-banks',
          method:'post',
          headers:{'Content-Type':'application/json'},
          data:{agent_id:props.user.id}
        }).then((response)=>{
          console.log(response)
          if (typeof(response.data) === 'object'){
            setBanks(Array.from(response.data))
          }
        }).catch((error)=>{setBanks([])})
      
      }, [props.user.id])

      const submitBank = () => {
        setIssaving(true)
        axios({
            url:API_QB+'add-bank',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{agent_id:props.user.id, name:bank}
          }).then((response)=>{
            if (response.data.id){
              swal('', 'Bank added successfully', 'success');
            }
          }).catch((error)=>{})
          setIssaving(false)
      }
      
      const deleteBank = (bank_id) => {
        axios({
            url:API_QB+'delete-bank',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{bank_id:bank_id}
          }).then((response)=>{
            if (response.data.status){
              swal('', 'Bank added successfully', 'success');
            }
          }).catch((error)=>{})
      }

      return (
        <>
            <Header title="Schooola - Question Bank" progress={100} />  
            <div className="card col-md-12">
            <div className="card user-data-card">
            <div className="card-body">
               <div className="single-profile-data d-flex align-items-center justify-content-between">
               <div className="title d-flex align-items-center"><h3>Add New Bank</h3></div>        
            </div>
            </div>
            
            <form>
                <div className="form-group text-left">
                <label htmlFor="bank">Bank name</label>
                <input type="text" className="form-control" id="bank" placeholder="Bank Name" value={bank} onChange={(e)=>{setBank(e.target.value)}}/>
                </div>
                <ReactTooltip/>
                <Button disabled={bank ? false:true} data-tip="Add Bank" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary pb-3"></span>} onClick={submitBank}></Button>
            </form>
                   
       </div>

                

    </div>
        
        <div className="card col-md-12 mb-100 mt-15">
        
        <table className="table table table-striped">
<thead>
<tr>
  <th scope="col">Bank</th>
  <th scope="col">Action</th>
</tr>
</thead>
<tbody>
{banks.map(bank => {
return (   
    
       <tr key={bank.id}>
       <td key={bank.id}>{ bank.name }</td>
       <td><span className="btn text-danger lni-trash" onClick={()=>deleteBank(bank.id)}></span></td>
       </tr>
     
       )
})}
</tbody>
</table>
                    
        </div>
            


            <Footer />
  
        </>
    );
}

export default Bank;