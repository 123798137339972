import React from 'react';
import { Link } from "react-router-dom";
import doLogout from '../Helpers/doLogout';



const SubagentFooter = () => {

  return (
      <div className="mt-100">
      <div className="footer-nav-area" id="footerNav">
      <div className="suha-footer-nav h-100">
      <ul className="h-100 d-flex align-items-center justify-content-between">
        <li className="active"><Link to="/home"><i className="lni-home"></i>Home</Link></li>          
        
        <li onClick={doLogout}><Link to="#"><i className="lni-power-switch"></i>Logout</Link></li>
      </ul>
      </div>
      </div>
      </div>
        );
};

export default SubagentFooter;