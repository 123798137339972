import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB } from '../../constants/apiContants';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Button from 'react-bootstrap-button-loader';
import toTitleCase from '../Helpers/changeCase';

const AddTournament = (props)=>{
    let [issaving, setIssaving] = useState(false);
    let [tournaments, setTournaments] = useState([]);
    let [tournamentids, setTournamentids] = useState([]);
    let [subjects, setSubjects] = useState([]);
    let [banks, setBanks] = useState([]);
    let [tournament, setTournament] = useState(0);
    let [subject, setSubject] = useState('');
    let [level, setLevel] = useState('');
    let [topic, setTopic] = useState('');
    let [questions, setQuestions] = useState(10);
    let [bank, setBank] = useState('private');
    let [duration, setDuration] = useState(10);

    useEffect(() => {
        // Get Tournies
        axios({
            url:API_QB+'agent-tournaments',
            method:'post',
            data:{agent_id:props.user.id, type:'quiz'},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            let _tournaments = []
            let _tournamentids = []
            response.data.map(tourny=>{
                _tournaments.push(tourny.name)
                _tournamentids.push(tourny.id)
                return tourny;
            })
            setTournaments(_tournaments);
            setTournamentids(_tournamentids);
            setTournament(_tournamentids[0])
        });
        // Get Banks
        axios({
            url:API_QB+'agent-banks',
            method:'post',
            data:{agent_id:props.user.id, has_questions:true},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            let _banks = []
            response.data.map(bank =>{
                _banks.push(bank.name)
                return bank;
            })
            setBanks(_banks);
        });
        // Get Subjects
        axios({
            url:API_QB+'get-subjects',
            method:'post',
            headers: {'Content-Type':'application/json'},
            data: {agent_id:props.user.id}
        }).then((response)=>{
            let _subjects = []
            response.data.map(question =>{
                _subjects.push(question.subject)
                return _subjects;
            })
            setSubjects(_subjects);
        });
    }, [props.user.id]);

    const doSubmit = () => {
        setIssaving(true);

        if(tournament){
            // save tournament
            axios({
                url:API_QB+'add-quiz',
                method:'post',
                data:{bank:bank, tournament_id:tournament, questions:questions, subject:subject, level:level, topic:topic, minutes:duration},
                headers:{'Content-Type':'application/json'}
            }).then((response)=>{
                if (response.data.id){
                    setIssaving(false);
                    swal('', 'Quiz created successfully!', 'success')
                }else{
                    setIssaving(false);
                    swal('', 'There was an error creating quiz!', 'info')
                }
            })
        }else{
            setIssaving(false);
            swal('', 'Ensure you have filled all fields!', 'info')
        }
    }


    return (
        <>
        <Header/>
        <h5>Create a Quiz</h5>
        <div className="row">
        <div className="form-group col-md-6">
            <label>Subjects</label>
            <select name="subject" value={subject} onChange={(e)=>{setSubject(e.target.value)}} className="form-control">
                {
                    subjects.map(subject => {
                    return(
                        <option key={subject} value={subject}>{toTitleCase(subject)}</option>)
                    })
                }
            </select>
        </div>
        <div className="form-group col-md-6">
            <label>Level</label>
            <select name="type" value={level} onChange={(e)=>{setLevel(e.target.value)}} className="form-control">
                <option value="Primary One">Primary One</option>
                <option value="Primary Two">Primary Two</option>
                <option value="Primary Three">Primary Three</option>
                <option value="Primary Four">Primary Four</option>
                <option value="Primary Five">Primary Five</option>
                <option value="Primary Six">Primary Six</option>
                <option value="Jss One">Jss One</option>
                <option value="Jss Two">Jss Two</option>
                <option value="Jss Three">Jss Three</option>
                <option value="SS One">SS One</option>
                <option value="SS Two">SS Two</option>
                <option value="SS Three">SS Three</option>
                <option value="">Any Level</option>
            </select>
        </div>
        <div className="form-group col-md-6">
            <label>Question Bank</label>
            <select name="bank" value={bank} onChange={(e)=>{setBank(e.target.value)}} className="form-control">
                {
                    banks.map(bank => {
                    return(
                        <option key={bank} value={bank}>{bank}</option>)
                    })
                }
            </select>
        </div>
        <div className="form-group col-md-6">
            <label>Tournament</label>
            <select name="tournament" value={tournament} onChange={(e)=>{setTournament(e.target.value)}} className="form-control">
            {
                tournaments.map((tourny, index) => {
                return(
                    <option key={tournamentids[index]} value={tournamentids[index]}>{tourny}</option>)
                })
            }
            </select>
        </div>
        <div className="form-group col-md-6">
            <label>Topic (optional)</label>
            <input name="topic" value={topic} placeholder="e.g. The Solar System | leave blank if not relevant" onChange={(e)=>{setTopic(e.target.value)}} className="form-control"/>
        </div>
        <div className="form-group col-md-3">
            <label>Number of Questions</label>
            <input type="number" name="questions" placeholder="e.g. 10" value={questions} onChange={(e)=>{setQuestions(e.target.value)}} className="form-control"/>
        </div>
        <div className="form-group col-md-3">
            <label>Duration (in minutes)</label>
            <input type="number" name="duration" placeholder="e.g. 10" value={duration} onChange={(e)=>{setDuration(e.target.value)}} className="form-control"/>
        </div>
        <div className="form-group col-md-6">
            <ReactTooltip/>
            <Button data-tip="Save Quiz" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary pb-3"></span>} onClick={doSubmit}></Button>
            <Link to="/quizzes" data-tip="My Quizzes" className="lni-list text-30 ml-3 text-primary" />
        </div>
        </div>
          
        <Footer/>
        </>
    )
}
export default AddTournament;