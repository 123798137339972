import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_QB } from '../../constants/apiContants';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const Quizzes = (props) => {
    let [quizzes, setQuizzes ] = useState([])
    Moment.globalFormat = 'D MMM YYYY @ h:MM:A';

    useEffect(()=>{
        axios({
            url:API_QB+'agent-quizzes',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data:{agent_id:props.user.id}
        }).then((response)=>{
            setQuizzes(response.data);
        });
    }, [props.user.id]);
    return (
        <>
        <Header title="Schooola - Quizzes" progress={100} />
        <div className="row">
        <div className="col-md-1">
        <Link data-tip="Back to Tournament Home" className="lni-arrow-left-circle text-30 m-2" to="/tournament"/>
        <Link data-tip="Create New Quiz" className="lni-write text-30 m-2" to="/add-quiz"/>
        <ReactTooltip/>
        </div>
        <div className="card col-md-11">
            <table className="table table table-striped">
            <thead>
                <tr>
                    <th scope="col">Tournament</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Questions</th>
                    <th scope="col">Level</th>
                    <th scope="col">Duration</th>
                    <th scope="col">Preview</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
            {quizzes.map(quiz => {
                return (   
        
                    <tr key={quiz.id+1}>
                        <td key={quiz.id+2}>
                        <span>{ quiz.tournament.name }</span>
                        </td>
                        <td key={quiz.id+3}>{ quiz.subject ? quiz.subject.toUpperCase():'-' }</td>
                        <td key={quiz.id+3}>{ quiz.questions.length }</td>
                        <td key={quiz.id+3}>{ quiz.level ? quiz.level.toUpperCase():'-' }</td>
                        <td key={quiz.id+3}>{ quiz.duration } minutes</td>
                        <td><span className="btn text-danger lni-image"></span></td>
                        <td><span className="btn text-danger lni-trash"></span></td>
                    </tr>
                    )
            })}
            </tbody>
            </table>        
        </div>
        </div>
        <Footer />
        </>
    );
}


export default Quizzes;