import React, { useEffect, useState } from 'react'
// import formatMoney from '../Helpers/formatMoney';
// import Moment from 'react-moment';
import axios from 'axios';
import { API_SRC } from '../../constants/apiContants';

const Subagents = (props) => {

    let [agents, setAgents] = useState([])

    const toggleAgent = (subagent_id, action) => {
        axios({
            url: API_SRC+'toggle-subagent',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data: {subagent_id:subagent_id, action:action}
        }).then(() => {
            props.setdoRefresh(!props.doRefresh);
        });
    }

    useEffect(()=>{
        axios({
            url: API_SRC+'subagents',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data: {agent_id:props.agent_id}
        }).then((response) => {
            if (typeof(response.data) === 'object'){

                setAgents(response.data);
            }
        })
    }, [props.agent_id, props.doRefresh])

    return (
        <>
        <h6 className="text-center">My Agents</h6>
        <div className="card">
        <table className="table table-responsive">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Status</th>
                    <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>

                {
                    agents.map(agent =>{
                        return(<tr key={agent.id}>
                            <td key={agent.id+1}>{agent.name}</td>
                            <td key={agent.id+2}>{agent.email}</td>
                            <td key={agent.id+3}>{agent.phone}</td>
                            <td key={agent.id+4}><span className={agent.is_active ?  "btn lni-check-mark-circle text-success" : "btn lni-cross-circle text-danger"} onClick={()=>{toggleAgent(agent.id, 'status')}}/></td>
                            <td key={agent.id+5}><span className="btn lni-trash text-danger" onClick={()=>{toggleAgent(agent.id, 'delete')}}/></td>
                        </tr>)
                    })
                }
            </tbody>
            </table>        
        </div>
        </>
        )
}

export default Subagents;