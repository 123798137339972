import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import axios from 'axios';
import { API_SRC } from '../../constants/apiContants';
// import Tiny from '../Editor/Tiny'
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import doUpload from '../Helpers/doUpload';
// import debitWallet from '../Helpers/debitWallet';
import Button from 'react-bootstrap-button-loader';
// import moment from 'moment';
// import Select from 'react-select'

const CreateSchool = (props)=>{
    let [issaving, setIssaving] = useState(false);
    // let [isdebited, setIsdebited] = useState(false);
    let [schoolLogo, setSchoolLogo] = useState('no-logo_fz6g2e');
    let [schoolName, setSchoolName] = useState();
    let [schoolAlias, setSchoolAlias] = useState();
    let [schoolAddress, setSchoolAddress] = useState();
    let [schoolEmail, setSchoolEmail] = useState();
    let [schoolPhone, setSchoolPhone] = useState();
    let [schoolTerm, setSchoolTerm] = useState();
    let [schoolSession, setSchoolSession] = useState();
    
    let [directorName, setDirectorName] = useState();
    let [directorPhone, setDirectorPhone] = useState();
    let [directorEmail, setDirectorEmail] = useState();
    let [directorPassword, setDirectorPassword] = useState();
    
    let [adminName, setAdminName] = useState();
    let [adminPhone, setAdminPhone] = useState();
    let [adminPassword, setAdminPassword] = useState();

    // const selectStyle = {input: styles => ({borderColor: "#ebebeb", height: "45px", padding: "12px 20px", fontSize: "14px"})}

    
    const doSubmit = () => {
        setIssaving(true);
        let payload = { 
                            admin:{
                                name:adminName,
                                phone:adminPhone,
                                password:adminPassword
                            },
                            director:
                            {
                                name:directorName,
                                phone: directorPhone,
                                email:directorEmail,
                                password:directorPassword
                            },
                            school:
                            {
                                logo:schoolLogo, 
                                name:schoolName, 
                                address:schoolAddress, 
                                phone:schoolPhone, 
                                email:schoolEmail, 
                                term:schoolTerm, 
                                session:schoolSession
                            }, 
                            agent_id:props.user.id
                        }
        axios({
            url:API_SRC+'add-school',
            method:'post',
            data:payload,
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            if (response.data.status === "success"){
                setIssaving(false);
                swal('', 'School created successfully!', 'success')
                setSchoolLogo('no-logo_fz6g2e');
            }else{
                setIssaving(false);
                swal('', 'There was an error creating school!', 'info')
            }
        })
    }


    return (
        <>
        <Header/>
        <div className="container">
        <h5>School Information</h5>
        <div className="row">
        <div className="form-group col-md-2">
            <label>Logo</label>
            <input name="logo" type="file" onChange={(async(e)=>{ 
                setIssaving(true);
                doUpload(e.target.files[0]).then((data)=>{
                    setSchoolLogo(data.Key);
                    setIssaving(false);
                })
            })} className="form-control" required />
        </div>
        <div className="form-group col-md-5">
            <label>Name</label>
            <input type="text" name="name" value={schoolName} onChange={(e)=>{setSchoolName(e.target.value)}} className="form-control" placeholder="e.g.: Awesome School Name" required />
        </div>
        <div className="form-group col-md-5">
            <label>Alias</label>
            <input type="text" name="alias" value={schoolAlias} onChange={(e)=>{setSchoolAlias(e.target.value)}} className="form-control" placeholder="e.g.: The Most Awesome School Alias" required />
        </div>
        <div className="form-group col-md-12">
            <label>Address</label>
            <input type="text" name="address" value={schoolAddress} onChange={(e)=>{setSchoolAddress(e.target.value)}} className="form-control" placeholder="e.g.: 5B Awesome Street, Somewhere Awesome" required />
        </div>
        <div className="form-group col-md-6">
            <label>Phone</label>
            <input type="tel" name="phone" value={schoolPhone} onChange={(e)=>{setSchoolPhone(e.target.value)}} className="form-control" placeholder="e.g.: +2347049012323" required />
        </div>
        <div className="form-group col-md-6">
            <label>Email</label>
            <input type="email" name="email" value={schoolEmail} onChange={(e)=>{setSchoolEmail(e.target.value)}} className="form-control" placeholder="e.g.: awesomeschool@domain.great" required />
        </div>
        <div className="form-group col-md-6">
            <label>Term</label>
            <input type="text" name="term" value={schoolTerm} onChange={(e)=>{setSchoolTerm(e.target.value)}} className="form-control" placeholder="e.g.: First Term" required />
        </div>
        <div className="form-group col-md-6">
            <label>Session</label>
            <input type="text" name="session" value={schoolSession} onChange={(e)=>{setSchoolSession(e.target.value)}} className="form-control" placeholder="e.g.: 2021/2022" required />
        </div>
        </div>
        
        <h5>School Director Information</h5>
        <div className="row">
        <div className="form-group col-md-6">
            <label>Name</label>
            <input type="text" name="name" value={directorName} onChange={(e)=>{setDirectorName(e.target.value)}} className="form-control" placeholder="e.g.: Awesome Name" required />
        </div>
        <div className="form-group col-md-6">
            <label>Phone</label>
            <input type="tel" name="phone" value={directorPhone} onChange={(e)=>{setDirectorPhone(e.target.value)}} className="form-control" placeholder="e.g.: +2347049012323" required />
        </div>
        <div className="form-group col-md-6">
            <label>Email</label>
            <input type="email" name="email" value={directorEmail} onChange={(e)=>{setDirectorEmail(e.target.value)}} className="form-control" placeholder="e.g.: awesomename@domain.great" required />
        </div>
        <div className="form-group col-md-6">
            <label>Password</label>
            <input type="password" name="password" value={directorPassword} onChange={(e)=>{setDirectorPassword(e.target.value)}} className="form-control" placeholder="e.g.: Something Secret" required />
        </div>
        </div>
        
        <h5>School Admin Information</h5>
        <div className="row">
        <div className="form-group col-md-4">
            <label>Name</label>
            <input type="text" name="name" value={adminName} onChange={(e)=>{setAdminName(e.target.value)}} className="form-control" placeholder="e.g.: Awesome Name" required />
        </div>
        <div className="form-group col-md-4">
            <label>Phone</label>
            <input type="tel" name="phone" value={adminPhone} onChange={(e)=>{setAdminPhone(e.target.value)}} className="form-control" placeholder="e.g.: +2347049012323" required />
        </div>
        <div className="form-group col-md-4">
            <label>Password</label>
            <input type="password" name="password" value={adminPassword} onChange={(e)=>{setAdminPassword(e.target.value)}} className="form-control" placeholder="e.g.: Something Secret" required />
        </div>

        <div className="form-group col-md-6">
            <ReactTooltip/>
            <Button data-tip="Save School" loading={issaving} bsStyle="default" spinColor="blue" icon={<span className="lni-save text-30 text-primary pb-3"></span>} onClick={doSubmit}></Button>
            <Link to="/schools" data-tip="My Schools" className="lni-list text-30 ml-3 text-primary" />
        </div>
        </div>

        </div>
          
        <Footer/>
        </>
    )
}
export default CreateSchool;