import React, { useEffect, useState } from 'react'
import formatMoney from '../Helpers/formatMoney';
import Moment from 'react-moment';
import axios from 'axios';
import { API_SRC } from '../../constants/apiContants';

const Transactions = (props) => {

    let [transactions, setTransactions] = useState([])

    useEffect(()=>{
        // Get Transactions
        axios({
            url:API_SRC+'agent-transactions',
            method:'post',
            data:{agent_id:props.agent_id},
            headers:{'Content-Type':'application/json'}
        }).then((response)=>{
            if (typeof(response.data) === 'object'){
                setTransactions(response.data);
            }
        });
    }, [props.agent_id, props.doRefresh])

    return (
        <>
        <h6 className="text-center">Recent Transactions</h6>
        <div className="card">
        <table className="table table-responsive">
            <thead>
                <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Type</th>
                    <th scope="col">Date</th>
                    <th scope="col">Narration</th>
                </tr>
            </thead>
            <tbody>
            {
                transactions.map(transaction =>{
                    return(<tr>
                        <td>{`₦${formatMoney(transaction.amount)}`}</td>
                        <td>{transaction.is_inflow? 'Credit':'Debit'}</td>
                        <td><Moment unix>{transaction.processed_on}</Moment></td>
                        <td>{transaction.narration}</td>
                    </tr>)
                })
            }
            </tbody>
            </table>        
        </div>
        </>
        )
}

export default Transactions;