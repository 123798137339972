import React, { useEffect, useState } from 'react';
import {API_SRC, CLOUDINARY_IMAGE, CIRCLE_TRANSFORM } from '../../constants/apiContants';
import { Link } from "react-router-dom";
import axios from 'axios';
import Header from '../Header/Header';
import Footer from '../Footer/Footer'
import NumberFormat from 'react-number-format'


const Home = (props) => {
        let [progress, setProgress] = useState(100);
        let [error, setError] = useState(null);
        let [wallet, setWallet] = useState(0);
        let [schools, setSchools] = useState(0);


        useEffect(()=>{
          // getSchools
          setProgress(50);
          setError(null);
          axios({
            url:API_SRC+'schools',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data: { "agent_id": props.user.id }
          }).then((response)=>{
            if (typeof(response.data) === 'object'){
              setSchools(response.data.length);
            }
          }).catch(()=>{
            setError('Could not fetch school data');
          });
          setProgress(70);
    
          // getWallet
          axios({
            url:API_SRC+'agent-wallet',
            method:'post',
            headers:{'Content-Type':'application/json'},
            data: { "agent_id": props.user.id }
          }).then((response)=>{
              setProgress(100);
              setWallet(response.data.available);
          }).catch(()=>{
              setError('Could not fetch wallet data');
          });

        }, [props.user.id]);



        return (
          <>
                <Header title="Schoola - Home" progress={progress} />
                <div className="alert alert-danger mt-2" style={{display: error ? 'block' : 'none' }} role="alert">{error}</div>
                <div className="content">
                <div className="card">
                  <div className="card-body p-4 d-flex align-items-center">
                  <div className="user-profile mr-3"><img width="100px" src={`${CLOUDINARY_IMAGE+CIRCLE_TRANSFORM}${localStorage.getItem('passport')}`} alt=""/></div>
                  <div className="user-info">
                    <p className="mb-0 text-black">{props.user.phone}</p>
                                <h5 className="mb-0">{props.user.name}</h5>
                                <p>{props.user.email}</p>

                                <p>Wallet <b><NumberFormat value={wallet} displayType={'text'} thousandSeparator={true} prefix={'₦'} /></b></p>
              </div>
            </div>
          </div>    
          <div className="card user-data-card">
            <div className="card-body">
              <div className="single-profile-data d-flex align-items-center justify-content-between">
                <div className="title d-flex align-items-center"><i className="lni-school-bench-alt"></i><span>Schools: {schools}</span></div>
                <div className="data-content"><Link to="/fund-wallet">Fund Wallet</Link></div>
              </div>
            
            </div>
          </div>
          <div className="edit-profile-btn mt-3"><Link className="btn btn-info w-100" to="/new-school"><i className="lni-plus mr-2"></i>New School</Link></div>
              


      <div className="product-catagories-wrapper pt-6">
          <div className="section-heading">
          </div>
          <div className="product-catagory-wrap">
            <div className="row">
              <div className="col-4">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="/schools"><i className="lni-school-bench-alt"></i><span>Schools</span></Link></div>
                </div>
              </div>
              <div className="col-4">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="/tournament"><i className="lni-cup"></i><span>Tournament</span></Link></div>
                </div>
              </div>
              <div className="col-4">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="/profile"><i className="lni-user"></i><span>Profile</span></Link></div>
                </div>
              </div>
              <div className="col-4">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="agents"><i className="lni-users"></i><span>Agents</span></Link></div>
                </div>
              </div>
              <div className="col-4">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="licence"><i className="lni-postcard"></i><span>Licences</span></Link></div>
                </div>
              </div>
              <div className="col-4">
                <div className="card mb-2 catagory-card">
                  <div className="card-body"><Link to="fund-wallet"><i className="lni-briefcase"></i><span>Transactions</span></Link></div>
                </div>
              </div>
            
            </div>
        </div>
                    </div>

                </div>

                <Footer />
           </>
        );
}

export default Home;